import Vue from "vue";
import VueRouter from "vue-router";
import sinoceanView from "../views/sinoceanView.vue";

Vue.use(VueRouter);

const routes = [
  // 中海通官网首页
  {
    path: "/",
    name: "sinoceanView",
    component: sinoceanView,
    meta: { title: "首页" },
  },
  {
    path: "/andHeaderEn",
    name: "andHeaderEn",
    component: () => import("../views/andHeaderEn.vue"),
    children: [
      {
        path: "/andHeaderEn/aboutOurEn",
        name: "aboutOur",
        meta: { title: "关于我们" },
        component: () => import("../views/aboutView/aboutOurEn.vue"),
      },
    ],
  },
  {
    path: "/andHeader",
    name: "andHeader",
    // component: andHeader,
    component: () => import("../views/andHeader.vue"),
    children: [
      {
        path: "/andHeader/newsCenter",
        name: "newsCenter",
        meta: { title: "新闻中心" },
        component: () => import("../views/aboutView/newsCenter.vue"),
      },
      {
        path: "/andHeader/newsDetail",
        name: "newsDetail",
        meta: { title: "新闻详情" }, //详情页
        component: () => import("../views/aboutView/newsDetail.vue"),
      },
      {
        path: "/andHeader/product",
        name: "product",
        meta: { title: "创新产品" },
        component: () => import("../views/aboutView/product.vue"),
      },
      {
        path: "/andHeader/business",
        name: "business",
        meta: { title: "核心业务" },
        component: () => import("../views/aboutView/product.vue"),
      },
      {
        path: "/andHeader/contactUs",
        name: "contactUs",
        meta: { title: "联系我们" },
        component: () => import("../views/aboutView/contactUs.vue"),
      },
      {
        path: "/andHeader/ESG",
        name: "ESG",
        meta: { title: "ESG" },
        component: () => import("../views/aboutView/ESG.vue"),
      },
      {
        path: "/andHeader/multimodal",
        name: "multimodal",
        meta: { title: "多式联运研发中心" },
        component: () => import("../views/aboutView/multimodal.vue"),
      },
      {
        path: "/andHeader/digitalize",
        name: "digitalize",
        meta: { title: "数字化平台" },
        component: () => import("../views/aboutView/digitalize.vue"),
      },
      {
        path: "/andHeader/recruit",
        name: "recruit",
        meta: { title: "招贤纳士" },
        component: () => import("../views/aboutView/recruit.vue"),
      },
      {
        path: "/andHeader/aboutOur",
        name: "aboutOur",
        meta: { title: "关于我们" },
        component: () => import("../views/aboutView/aboutOur.vue"),
      },
    ],
  },
];
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
  // 不知道为啥vue会自动缓存滚动条位置，这个方法用来重置
  scrollBehavior(to, from, savedPssition) {
    // if(savedPssition){
    // 	return savedPssition;
    // }else{
    return { x: 0, y: 0 };
    // }
  },
});

export default router;

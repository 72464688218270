<template>
  <div>
    <!-- 首页 -->
    <div id="wrap">
      <div id="main">
        <!-- 头部首页 -->
        <HeaderInfo
          :currentScroll="currentScroll"
          :screenHeight="screenHeight"
          :tabsList.sync="tabsList"
          :footerInfo.sync="footerInfo"
          :titleStyle="titleStyle"
          @childByValue="childByValue"></HeaderInfo>
        <DividingLine
          v-if="showWeb"
          data-aos="zoom-in"
          data-aos-easing="ease-out"
          data-aos-duration="1500"
          data-aos-offset="10"></DividingLine>

        <!-- 关于我们 -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[0].bigPic})`,
            height: clientWidth >= 1920 ? '882px' : '816px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%" ref="showAboutUs">
            <div style="padding: 9% 4% 5%">
              <el-row :gutter="40" style="max-height: 522px">
                <el-col
                  :lg="11"
                  :xl="10"
                  style="position: relative"
                  data-aos="fade-right"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1500">
                  <!-- class="wow slideInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                  data-wow-offset="0"
                  data-wow-iteration="1" -->
                  <img style="border-radius: 5px" :src="blue" alt="" />
                  <div>
                    <video
                      ref="movie"
                      class="video"
                      :src="tabsList[0].videoUrl"
                      controls
                      :poster="tabsList[0].videoCover"
                      style="
                        width: 100%;
                        border-radius: 5px;
                        bottom: 125px;
                      "></video>
                  </div>
                  <div
                    style="
                      padding-top: 40px;
                      color: #195594;
                      font-size: 28px;
                      width: 100%;
                      text-align: center;
                      font-weight: 600;
                    ">
                    诚信尽责 创新实干 成就客户 合作共赢
                  </div>
                </el-col>
                <el-col :lg="13" :xl="14">
                  <div
                    style="margin-left: 80px; position: relative; top: -35px">
                    <div data-aos="zoom-in" data-aos-duration="1500">
                      <img :src="titleBg" alt="" />
                      <p class="title-p">深圳市中海通物流股份有限公司</p>
                      <!-- <p
                        style="
                          font-weight: 600;
                          text-align: left;
                          color: #195594;
                          line-height: 32px;
                          font-size: 28px;
                          position: absolute;
                          top: 20px;
                          right: 30px;
                          font-size: 32px;
                        "
                        :style="{
                          position: titleStyle ? 'absolute' : '',
                          margin: titleStyle ? '0' : '20px 0 0 88px',
                        }"
                      >
                        SINOCEAN LOGISTICS
                      </p> -->
                      <el-divider></el-divider>
                    </div>

                    <div
                      style="height: 310px"
                      data-aos="fade-left"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="1500">
                      <!-- <div
                        class="ql-editor title-val"
                        v-html="aboutDetail.menuContent"
                      ></div> -->
                      <p class="title-val">
                        深圳市中海通物流股份有限公司成立于1999年3月，总部设立于深圳前海自贸区，在全国分布有广泛的分支机构。作为国内最早从事多式联运业务的企业，通过构建多式联运物流枢纽和多式联运物流通道，布局全国多式联运物流网络，业务覆盖华北、西北、东北、华中、华东、华南、西南等区域，并较早实现铁路箱规模化下水以及国际集装箱一箱到底、一票到底，
                        开创了多式联运的先河。
                      </p>
                      <p
                        class="title-val"
                        style="
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 4;
                          overflow: hidden;
                        ">
                        中海通致力多式联运的研究，业务包括：内外贸集装箱海运物流、内外贸散杂货海运物流、公铁联运和铁水联运、散改集和棚改集、港口物流与驳运服务、公路干线与仓储配送、航线经营与船舶管理、工程物流与特种运输等。多年来，通过对各种运输方式的有效整合，建立“干支衔接、枢纽集散”的服务体系和“东西互济、南北贯通、陆海联动、内外循环”的发展格局，实现全国大物流的互联互通。
                      </p>
                      <p
                        class="title-val"
                        v-if="clientWidth >= 1920"
                        style="
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 3;
                          overflow: hidden;
                        ">
                        中海通紧紧围绕公司发展战略，聚焦枢纽建设、通道建设、网络建设，不断创新，持续为客户创造价值。同时，公司自主研发、自我创新，走“智能化、移动化、平台化”发展道路，打造具有领先优势的多式联运数字化产品。中海通坚定不移选择多式联运的赛道，以长期主义的思想，以“创新+实干”的精神，积极推动多式联运行业的进步，为国家绿色低碳运输做出自己应有的贡献。
                      </p>
                    </div>

                    <div
                      data-aos="fade-left"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="1500"
                      class="light"
                      style="margin-top: 20px">
                      <el-button
                        style="
                          background: transparent;
                          font-size: 14px;
                          color: #666;
                          border-color: #666;
                        "
                        @click="
                          $router.push(
                            '/andHeader/aboutOur?type=关于我们&name=公司简介'
                          )
                        "
                        >查看详情
                        <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt="" />
                        <img height="20" class="green" :src="icon1" alt="" />
                      </el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div
              v-if="showWeb"
              class="bgfooter flex justify-between"
              :style="{ '--topHeight': bgTopStyle }">
              <div
                class="flex hover-opacity2"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
                data-aos-delay="0"
                data-aos-offset="10"
                :style="{ opacity: aboutMouse ? '0.5' : '1' }">
                <div class="relative">
                  <span class="margin-right-xs" style="font-size: 36px"
                    >1999</span
                  >
                  <span class="absolute" style="font-size: 14px">年</span>
                </div>
                <div
                  style="margin-top: 13px"
                  :style="{ fontSize: aboutMouse ? '20px' : '18px' }">
                  <span style="line-height: 26px; margin-left: 18px"
                    >中海通成立
                    <!-- <img
                      :class="imgclass"
                      :src="icon2"
                      alt=""
                  /> -->
                  </span>
                </div>
              </div>
              <div
                style="margin-top: 13px"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
                data-aos-delay="10"
                data-aos-offset="10">
                <span style="font-size: 18px">
                  <img :class="imgclass" :src="icon2" alt="" />
                </span>
              </div>

              <div style="margin-top: 13px; cursor: pointer" class="flex">
                <div
                  data-aos="fade-right"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  data-aos-delay="20"
                  data-aos-offset="10"
                  @click="
                    $router.push(
                      `/andHeader/${tabsList[0].path}?type=${tabsList[0].menuName}&name=${tabsList[0].voList[0].menuName}`
                    )
                  ">
                  <span
                    class="hover-opacity"
                    style="font-size: 18px; line-height: 26px"
                    :style="{ opacity: aboutMouse ? '0.5' : '1' }"
                    ><img
                      width="16"
                      class="margin-right-sm"
                      style="margin-bottom: 3px"
                      :src="icon3"
                      alt="" />{{ tabsList[0].voList[0].menuName }}
                  </span>
                </div>
                <div
                  data-aos="fade-right"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  data-aos-delay="30"
                  data-aos-offset="10">
                  <span style="font-size: 18px">
                    <img :class="imgclass" :src="icon2" alt="" />
                  </span>
                </div>
              </div>

              <div
                style="margin-top: 13px"
                v-for="(v, i) in aboutList"
                :key="i"
                class="flex">
                <div
                  data-aos="fade-right"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  data-aos-offset="10"
                  :data-aos-delay="v.delay"
                  @click="
                    $router.push(
                      `/andHeader/${tabsList[0].path}?type=${
                        tabsList[0].menuName
                      }&name=${
                        v.name == '使命'
                          ? '企业使命'
                          : v.name == '愿景'
                          ? '企业愿景'
                          : v.name == '核心价值观'
                          ? '核心价值观'
                          : v.name
                      }`
                    )
                  ">
                  <span
                    class="hover-opacity"
                    style="font-size: 18px; line-height: 26px; opacity: 0.5"
                    @mouseover="aboutMouse = true"
                    ><img
                      width="16"
                      class="margin-right-sm"
                      style="margin-bottom: 3px"
                      :src="v.icon"
                      alt="" />{{ v.name }}
                  </span>
                </div>
                <div
                  data-aos="fade-right"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1500"
                  data-aos-offset="10"
                  :data-aos-delay="v.delayLeft">
                  <span style="font-size: 18px">
                    <img :class="imgclass" :src="icon2" alt="" />
                  </span>
                </div>
              </div>
            </div>
            <DividingLine
              data-aos="zoom-in"
              data-aos-easing="ease-out"
              data-aos-duration="1500"
              data-aos-offset="10"
              style="position: relative; top: -5px"
              :style="{ top: bgTopStyle }"></DividingLine>
          </div>
        </div>

        <!-- 关于我们-手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[0].bigPic})`,
            height: '680px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%; position: relative" ref="showAboutUs">
            <el-row style="padding: 15px">
              <el-col :span="24">
                <div
                  style="margin-top: 28px"
                  data-aos="zoom-in"
                  data-aos-easing="ease-out"
                  data-aos-duration="1500"
                  data-aos-offset="0">
                  <div
                    class="text-center"
                    data-aos="zoom-in"
                    data-aos-duration="1500">
                    <p class="title-p-mobile">深圳市中海通物流股份有限公司</p>
                    <p
                      style="
                        font-weight: 400;
                        color: #333;
                        line-height: 13px;
                        font-size: 13px;
                        margin-bottom: 30px;
                      ">
                      SHENZHEN SINOCEAN LOGISTICS CO., LTD
                    </p>
                  </div>

                  <div
                    style="margin-tb-sm"
                    data-aos="zoom-in"
                    data-aos-easing="ease-out"
                    data-aos-duration="1500"
                    data-aos-offset="0">
                    <video
                      ref="movie"
                      :src="tabsList[0].videoUrl"
                      controls
                      :poster="tabsList[0].videoCover"
                      style="width: 100%; border-radius: 5px"></video>
                  </div>
                  <div
                    style="
                      color: #195594;
                      font-size: 14px;
                      width: 100%;
                      text-align: center;
                      font-weight: 600;
                      margin-top: 10px;
                    ">
                    <span class="margin-right">诚信尽责</span>
                    <span class="margin-right">创新实干</span>
                    <span class="margin-right">成就客户</span>
                    <span>合作共赢</span>
                  </div>

                  <div
                    style="margin-top: 20px"
                    data-aos="fade-left"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1500">
                    <!-- <div
                        class="ql-editor title-val"
                        v-html="aboutDetail.menuContent"
                      ></div> -->
                    <p class="title-val">
                      深圳市中海通物流股份有限公司成立于1999年3月，总部设立于深圳前海自贸区，在全国分布有广泛的分支机构。作为国内最早从事多式联运业务的企业，通过构建多式联运物流枢纽和多式联运物流通道，布局全国多式联运物流网络，业务覆盖华北、西北、东北、华中、华东、华南、西南等区域，并较早实现铁路箱规模化下水以及国际集装箱一箱到底、一票到底，
                      开创了多式联运的先河。
                    </p>
                  </div>

                  <div
                    data-aos="fade-left"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1500"
                    class="light text-center"
                    style="margin-top: 20px">
                    <el-button
                      style="
                        background: #24ad0f;
                        font-size: 14px;
                        color: #fff;
                        border-color: #24ad0f;
                      "
                      @click="
                        $router.push(
                          '/andHeader/aboutOur?type=关于我们&name=公司简介'
                        )
                      "
                      >查看详情
                      <!-- <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt=""
                        /> -->
                      <img height="20" :src="icon1" alt="" />
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
            <DividingLine
              data-aos="zoom-in"
              data-aos-easing="ease-out"
              data-aos-duration="1500"
              data-aos-offset="10"
              style="position: absolute; bottom: 0; width: 100%"></DividingLine>
          </div>
        </div>

        <!-- 核心业务 -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[1].bigPic})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              style="padding: 6% 2% 4%"
              data-aos="zoom-in"
              data-aos-easing="ease-out"
              data-aos-duration="1500">
              <span class="hexinTitle">{{ tabsList[1].menuName }}</span>
              <div class="margin-top text-20">
                {{ tabsList[1].title }}
              </div>
            </div>

            <div
              style="padding: 0% 4% 2%; font-size: 18px"
              v-if="tabsList[1].voList.length > 10">
              <el-row :gutter="20">
                <!-- 1 -->
                <el-col :lg="8">
                  <el-row :gutter="20">
                    <el-col :lg="18" :xl="17">
                      <el-row :gutter="20" class="margin-bottom-lg">
                        <el-col :lg="8" :xl="7">
                          <div
                            style="height: 110px"
                            class="item-div div_hover_gcwl"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[16],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[16].menuName
                            }}</span>
                          </div>
                        </el-col>
                        <el-col :lg="16" :xl="17">
                          <div
                            style="height: 110px"
                            class="item-div div_hover_glgx"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[11],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[11].menuName
                            }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :lg="16" :xl="17">
                          <div
                            style="height: 110px"
                            class="item-div margin-bottom-lg div_hover_ccps"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[12],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[12].menuName
                            }}</span>
                          </div>

                          <div
                            style="height: 110px"
                            class="item-div margin-bottom-lg div_hover_cbzp"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[14],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[14].menuName
                            }}</span>
                          </div>

                          <div
                            style="height: 110px"
                            class="item-div margin-bottom-lg div_hover_dzspgyl"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[18],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[18].menuName
                            }}</span>
                          </div>
                        </el-col>
                        <el-col :lg="8" :xl="7">
                          <div
                            style="height: 175px"
                            class="item-div margin-bottom-lg div_hover_byfw"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[10],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[10].menuName
                            }}</span>
                          </div>

                          <div
                            style="height: 175px"
                            class="item-div div_hover_cbgl"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[13],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[13].menuName
                            }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :lg="6" :xl="7">
                      <div
                        style="height: 240px"
                        class="item-div margin-bottom-lg div_hover_gsly"
                        data-aos="fade-down"
                        data-aos-duration="1000"
                        @click="
                          toDetail(
                            tabsList[1].voList[2],
                            0,
                            tabsList[1].menuName
                          )
                        ">
                        <span style="width: 18%" class="item-span">{{
                          tabsList[1].voList[2].menuName
                        }}</span>
                      </div>

                      <div
                        style="height: 240px"
                        class="item-div div_hover_nmjzx"
                        data-aos="fade-down"
                        data-aos-duration="1000"
                        @click="
                          toDetail(
                            tabsList[1].voList[4],
                            0,
                            tabsList[1].menuName
                          )
                        ">
                        <span style="width: 18%" class="item-span">{{
                          tabsList[1].voList[4].menuName
                        }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>

                <!-- 2 -->
                <el-col :lg="8">
                  <div
                    style="min-height: 110px"
                    class="item-div margin-bottom-lg div_hover_gtly img_hover1"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    @click="
                      toDetail(tabsList[1].voList[1], 0, tabsList[1].menuName)
                    ">
                    <span class="item-span">{{
                      tabsList[1].voList[1].menuName
                    }}</span>
                  </div>

                  <div
                    style="
                      height: 370px;
                      margin: 0px;
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                    "
                    class="item-div div_hover_tsly"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    @click="
                      toDetail(tabsList[1].voList[0], 0, tabsList[1].menuName)
                    ">
                    <!-- <img
                        class="imgbg"
                        style="height: 100%; width: 100%"
                        :src="bg5"
                        alt=""
                      /> -->
                    <span class="item-span">{{
                      tabsList[1].voList[0].menuName
                    }}</span>
                  </div>
                </el-col>

                <!-- 3 -->
                <el-col :lg="8">
                  <el-row :gutter="20">
                    <el-col :lg="6" :xl="7">
                      <div
                        style="height: 240px"
                        class="item-div margin-bottom-lg div_hover_ssly"
                        data-aos="fade-down"
                        data-aos-duration="1000"
                        @click="
                          toDetail(
                            tabsList[1].voList[3],
                            0,
                            tabsList[1].menuName
                          )
                        ">
                        <span style="width: 18%" class="item-span"
                          >{{ tabsList[1].voList[3].menuName }}
                        </span>
                      </div>

                      <div
                        style="height: 240px"
                        class="item-div div_hover_wmjzx"
                        data-aos="fade-down"
                        data-aos-duration="1000"
                        @click="
                          toDetail(
                            tabsList[1].voList[5],
                            0,
                            tabsList[1].menuName
                          )
                        ">
                        <span style="width: 18%" class="item-span"
                          >{{ tabsList[1].voList[5].menuName }}
                        </span>
                      </div>
                    </el-col>
                    <el-col :lg="18" :xl="17">
                      <el-row :gutter="20" class="margin-bottom-lg">
                        <el-col :lg="16" :xl="17">
                          <div
                            style="height: 110px"
                            class="item-div div_hover_nmszh"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[7],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[7].menuName
                            }}</span>
                          </div>
                        </el-col>
                        <el-col :lg="8" :xl="7">
                          <div
                            style="height: 110px"
                            class="item-div div_hover_jrwl"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[17],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[17].menuName
                            }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :lg="8" :xl="7">
                          <div
                            style="height: 175px"
                            class="item-div margin-bottom-lg div_hover_sgj"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[8],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span
                              style="writing-mode: vertical-rl"
                              class="item-span"
                              >{{ tabsList[1].voList[8].menuName }}</span
                            >
                          </div>

                          <div
                            style="height: 175px"
                            class="item-div div_hover_gkwl"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[9],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[9].menuName
                            }}</span>
                          </div>
                        </el-col>
                        <el-col :lg="16" :xl="17">
                          <div
                            style="height: 110px"
                            class="item-div margin-bottom-lg div_hover_wmszh"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[6],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[6].menuName
                            }}</span>
                          </div>

                          <div
                            style="height: 110px"
                            class="item-div margin-bottom-lg div_hover_cbjy"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            @click="
                              toDetail(
                                tabsList[1].voList[15],
                                0,
                                tabsList[1].menuName
                              )
                            ">
                            <span class="item-span">{{
                              tabsList[1].voList[15].menuName
                            }}</span>
                          </div>

                          <div
                            @click="
                              $router.push(
                                `/andHeader/business?type=${tabsList[1].menuName}`
                              )
                            "
                            style="height: 110px"
                            class="item-div margin-bottom-lg div_hover_gengduo"
                            data-aos="fade-left"
                            data-aos-duration="1000">
                            <span
                              class="item-span"
                              @click="
                                $router.push(
                                  `/andHeader/business?type=${tabsList[1].menuName}`
                                )
                              "
                              >了解更多
                              <img :src="icon1" alt="" />
                            </span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- 核心业务手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[1].bigPic})`,
            height: '655px',
          }"
          class="relative"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div
            style="padding: 40px 15px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[1].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[1].title }}
            </div>
          </div>
          <!-- 轮播 -->
          <div style="padding: 0 15px" class="text-center">
            <el-carousel ref="nop1" indicator-position="outside" arrow="never">
              <el-carousel-item v-for="(v, i) in carList" :key="i">
                <el-row :gutter="10">
                  <el-col
                    :span="12"
                    class="text-center margin-bottom"
                    v-for="(c, f) in v.list"
                    :key="f"
                    data-aos="zoom-in"
                    data-aos-easing="ease-out"
                    data-aos-duration="1500"
                    data-aos-offset="0">
                    <div
                      @touchstart="start($event)"
                      @touchmove="move($event)"
                      @click="toDetail(c, 0, c.menuName)">
                      <img
                        style="border-radius: 8px"
                        height="158"
                        :src="c.smallPic"
                        alt="" />
                      <p class="margin-top hx-mobile">{{ c.menuName }}</p>
                    </div>
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              class="light text-center"
              style="margin-top: 20px">
              <el-button
                style="
                  background: #24ad0f;
                  font-size: 14px;
                  color: #fff;
                  border-color: #24ad0f;
                "
                @click="$router.push('/andHeader/business?type=核心业务&name=')"
                >查看更多
                <!-- <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt=""
                        /> -->
                <img height="20" :src="icon1" alt="" />
              </el-button>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- 创新产品 -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[2].bigPic})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              data-aos="zoom-in"
              data-aos-easing="ease-out"
              data-aos-duration="1500"
              style="padding: 4% 2% 2%">
              <span
                class="hexinTitle"
                style="text-shadow: 0px 2px rgba(0, 0, 0, 0.5)"
                >{{ tabsList[2].menuName }}</span
              >
              <div
                class="margin-top text-20"
                style="text-shadow: 0px 2px rgba(0, 0, 0, 0.5)">
                {{ tabsList[2].title }}
              </div>
            </div>
            <div
              style="padding: 0% 4% 2%; font-size: 18px; color: #fff"
              v-if="swiperList.length > 0">
              <!-- 创新产品无限循环轮播1 -->
              <swiper
                :options="swiperOption"
                ref="mySwiper"
                class="wow slideInLeft"
                data-wow-duration="2s"
                data-wow-delay="0s"
                data-wow-offset="0"
                data-wow-iteration="1">
                <swiper-slide
                  v-for="(v, i) in swiperList.slice(0, 7)"
                  :key="i"
                  class="swiper-item">
                  <div
                    @click="toDetail(v, 1)"
                    style="
                      height: 200px;
                      width: 280px;
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      background-blend-mode: multiply;
                    "
                    class="scroll-div"
                    :style="{
                      backgroundImage: `url(${v.indexBackground})`,
                    }">
                    <div class="scroll_p">
                      <p class="scroll_title">
                        {{ v.title }}
                      </p>
                      <p class="scroll_title2">
                        {{ v.subtitle }}
                      </p>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>

              <!-- 创新产品无限循环轮播2 -->
              <swiper
                :options="swiperOption2"
                class="wow fadeLeft"
                ref="mySwiper"
                data-wow-duration="2s"
                style="margin-top: 15px">
                <swiper-slide
                  v-for="(v, i) in swiperList.slice(8, 14)"
                  :key="i"
                  class="swiper-item">
                  <div
                    @click="toDetail(v, 1)"
                    style="
                      height: 200px;
                      width: 280px;
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      background-blend-mode: multiply;
                    "
                    class="scroll-div"
                    :style="{
                      backgroundImage: `url(${v.indexBackground})`,
                    }">
                    <div class="scroll_p">
                      <p class="scroll_title">
                        {{ v.title }}
                      </p>
                      <p class="scroll_title2">
                        {{ v.subtitle }}
                      </p>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>

              <!-- 创新产品无限循环轮播3 -->
              <swiper
                :options="swiperOption"
                ref="mySwiper"
                class="wow slideInLeft"
                data-wow-duration="2s"
                data-wow-delay="0s"
                data-wow-offset="0"
                data-wow-iteration="1"
                style="margin-top: 15px">
                <swiper-slide
                  v-for="(v, i) in swiperList.slice(15, 22)"
                  :key="i"
                  class="swiper-item">
                  <div
                    @click="toDetail(v, 1)"
                    style="
                      height: 200px;
                      width: 280px;
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      background-blend-mode: multiply;
                    "
                    class="scroll-div"
                    :style="{
                      backgroundImage: `url(${v.indexBackground})`,
                    }">
                    <div class="scroll_p">
                      <p class="scroll_title">
                        {{ v.title }}
                      </p>
                      <p class="scroll_title2">
                        {{ v.subtitle }}
                      </p>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- 创新产品手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[2].bigPic})`,
            height: '500px',
          }"
          style="
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
          ">
          <div
            style="padding: 40px 15px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[2].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[2].title }}
            </div>
          </div>
          <div style="padding: 0 15px" class="text-center">
            <!-- 无限循环轮播1 -->
            <swiper
              :options="swiperOption_mobile"
              ref="mySwiper"
              class="wow slideInLeft"
              data-wow-duration="2s"
              data-wow-delay="0s"
              data-wow-offset="0"
              data-wow-iteration="1">
              <swiper-slide
                v-for="(v, i) in swiperList.slice(0, 22)"
                :key="i"
                class="swiper-item">
                <div
                  @click="toDetail(v, 1)"
                  style="
                    height: 250px;
                    width: 210px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-blend-mode: multiply;
                  "
                  class="scroll-div"
                  :style="{
                    backgroundImage: `url(${v.indexBackground})`,
                  }">
                  <div class="scroll_p">
                    <p class="scroll_title">
                      {{ v.title }}
                    </p>
                    <p class="scroll_title2">
                      {{ v.subtitle }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              class="light text-center"
              style="margin-top: 40px">
              <el-button
                style="
                  background: #24ad0f;
                  font-size: 14px;
                  color: #fff;
                  border-color: #24ad0f;
                "
                @click="$router.push('/andHeader/product?type=创新产品&name=')"
                >查看更多
                <!-- <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt=""
                        /> -->
                <img height="20" :src="icon1" alt="" />
              </el-button>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- 新闻中心 -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[3].bigPic})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              data-aos="zoom-in"
              data-aos-easing="ease-out"
              data-aos-duration="1500"
              style="padding: 5% 4% 0%">
              <span class="hexinTitle">{{ tabsList[3].menuName }}</span>
              <div class="margin-top text-20">
                {{ tabsList[3].title }}
              </div>
              <!-- 二级菜单按钮 -->
              <div style="text-align: end; position: relative; top: -85px">
                <el-button
                  v-for="(v, i) in tabsList[3].voList.slice(0, 5)"
                  :key="i"
                  size="medium"
                  style="background: transparent; color: #fff"
                  @click="
                    $router.push(
                      `/andHeader/newsCenter?type=${tabsList[3].menuName}&name=${v.menuName}&show=0`
                    )
                  "
                  >{{ v.menuName }}</el-button
                >
              </div>
            </div>

            <div style="padding: 0 4% 1%">
              <el-row :gutter="20">
                <!-- 新闻视频 -->
                <el-col
                  :span="9"
                  data-aos="flip-up"
                  data-aos-duration="1500"
                  data-aos-offset="300">
                  <div v-for="(v, i) in newsList.slice(0, 1)" :key="i">
                    <div class="margin-bottom-sm">
                      <!-- 新闻图片 -->
                      <img
                        style="height: 450px; border-radius: 5px"
                        :src="v.smallPic"
                        alt="" />
                    </div>
                    <div
                      class="padding news_hover"
                      style="
                        background: #00498b;
                        border-radius: 4px;
                        height: 140px;
                      ">
                      <!-- 新闻标题 -->
                      <div
                        class="margin-bottom-sm"
                        style="
                          color: #fff;
                          font-size: 18px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        ">
                        {{ v.title }}
                      </div>
                      <!-- 新闻内容 -->
                      <p
                        style="
                          color: #b6ceff;
                          font-weight: 400;
                          font-size: 14px;
                          text-indent: 2em;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 2;
                          overflow: hidden;
                        ">
                        {{ v.description }}
                      </p>
                      <!-- 新闻日期 查看按钮 -->
                      <div
                        class="margin-top-sm text-white flex justify-between">
                        <div>
                          <span class="margin-right">{{ v.publishTime }}</span>
                        </div>
                        <div class="btn_hover" @click="toDetail(v, 2)">
                          <span class="text-df"
                            >查看
                            <img class="white" :src="icon1" alt="" />
                            <img class="green" :src="icon_green" alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
                <!-- 新闻轮播 -->
                <el-col
                  data-aos="flip-up"
                  data-aos-offset="300"
                  data-aos-duration="1500"
                  :span="6">
                  <el-carousel arrow="never" @change="changeCarousel">
                    <el-carousel-item
                      v-for="(item, index) in newsList.slice(1, 5)"
                      :key="index"
                      @click.native="toDetail(item, 2)">
                      <div class="pie_item">
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          alt=""
                          class="blue" />
                        <img
                          style="width: 100%; height: 100%"
                          :src="item.img2"
                          alt=""
                          class="green" />
                        <p class="pie_item_p1">{{ "推荐新闻" }}</p>
                        <div class="radius_white"></div>
                        <p class="pie_item_p2">{{ item.title }}</p>

                        <p class="pie_item_p3">
                          {{ item.description }}
                        </p>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                  <div
                    style="
                      height: 75px;
                      background: #00498b;
                      border-radius: 0 0 4px 4px;
                      color: #fff;
                    ">
                    <div class="padding-lr text-right padding-top-xl btn_hover">
                      <span class="text-df pointer"
                        >查看
                        <img class="white" :src="icon1" alt="" />
                        <img class="green" :src="icon_green" alt="" />
                      </span>
                    </div>
                  </div>
                </el-col>
                <!-- 新闻内容 -->
                <el-col :span="9">
                  <div
                    class="padding news_hover"
                    style="
                      background: #00498b;
                      border-radius: 4px;
                      margin-bottom: 13px;
                      height: 140px;
                    "
                    v-for="(v, i) in newsList.slice(5, 9)"
                    :key="i"
                    :data-aos-delay="v.delay"
                    data-aos="flip-up"
                    data-aos-duration="1500">
                    <div
                      class="margin-bottom-sm"
                      style="
                        color: #fff;
                        font-size: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      ">
                      {{ v.title }}
                    </div>
                    <p
                      style="
                        color: #b6ceff;
                        font-weight: 400;
                        font-size: 14px;
                        text-indent: 2em;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      ">
                      {{ v.description }}
                    </p>
                    <div class="margin-top-sm text-white flex justify-between">
                      <div>
                        <span class="margin-right">{{ v.publishTime }}</span>
                      </div>
                      <div class="btn_hover" @click="toDetail(v, 2)">
                        <span class="text-df"
                          >查看
                          <img class="white" :src="icon1" alt="" />
                          <img class="green" :src="icon_green" alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- 新闻中心手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[3].smallPic})`,
            height: '700px',
          }"
          style="
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
          ">
          <div
            style="padding: 40px 15px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[3].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[3].title }}
            </div>
          </div>

          <div style="padding: 0 15px">
            <div
              class="relative"
              v-if="newsList.length > 0"
              data-aos="zoom-in"
              data-aos-easing="ease-out"
              data-aos-duration="1500"
              data-aos-offset="0"
              @click="toDetail(newsList[0], 2)">
              <img
                height="206"
                width="100%"
                style="border-radius: 8px"
                :src="newsList[0].smallPic"
                alt="" />
              <p
                class="text-white news-mobile"
                style="
                  font-weight: 600;
                  text-shadow: 0px 2px rgba(0, 0, 0, 0.6);
                ">
                {{ newsList[0].title }}
              </p>
            </div>
            <div
              class="news_hover_div"
              v-for="(v, i) in newsList.slice(1, 4)"
              :key="i"
              @click="toDetail(v, 2)"
              data-aos="zoom-in"
              data-aos-easing="ease-out"
              data-aos-duration="1500"
              data-aos-offset="0">
              <el-row :gutter="10">
                <el-col :span="8">
                  <img
                    :src="v.smallPic"
                    alt=""
                    style="border-radius: 2px"
                    width="100%"
                    height="64" />
                </el-col>
                <el-col :span="16">
                  <p class="news_title_p">
                    {{ v.title }}
                  </p>

                  <div class="flex justify-between" style="color: #999">
                    <div>
                      <img
                        width="12"
                        style="margin-bottom: 2px; margin-right: 5px"
                        src="./aboutView/img/news3.png"
                        alt="" />
                      <span class="margin-right-lg">{{ v.publishTime }}</span>
                    </div>

                    <div>
                      <img
                        width="12"
                        style="margin-bottom: 2px; margin-right: 5px"
                        src="./aboutView/img/news1.png"
                        alt="" />
                      <span class="margin-right-lg">{{ v.clickNum }}</span>

                      <img
                        width="12"
                        style="margin-bottom: 2px; margin-right: 5px"
                        src="./aboutView/img/news2.png"
                        alt="" />
                      <span>{{ v.shareNum }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- 查看更多按钮 -->
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              class="light text-center"
              style="margin-top: 30px">
              <el-button
                style="
                  background: #24ad0f;
                  font-size: 14px;
                  color: #fff;
                  border-color: #24ad0f;
                "
                @click="
                  $router.push(
                    '/andHeader/newsCenter?type=新闻中心&name=行业聚焦'
                  )
                "
                >查看更多
                <!-- <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt=""
                        /> -->
                <img height="20" :src="icon1" alt="" />
              </el-button>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- 多式联运研发中心 -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[4].bigPic})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="1500"
              style="padding: 5% 4% 3%">
              <span
                class="hexinTitle"
                style="text-shadow: 0px 2px rgba(0, 0, 0, 0.5)"
                >{{ tabsList[4].menuName }}</span
              >
              <div
                class="margin-top text-20"
                style="text-shadow: 0px 2px rgba(0, 0, 0, 0.5)">
                {{ tabsList[4].title }}
              </div>
              <!-- <div
                style="
                  text-align: right;
                  left: 93.5%;
                  position: relative;
                  top: -85px;
                  width: 116px;
                  height: 32px;
                "
                class="hover_more"
              >
                <img class="pointer white" :src="more" alt="" />
                <img class="pointer green" :src="more_green" alt="" />
              </div> -->
            </div>

            <div style="padding: 0 4% 0">
              <el-row :gutter="20">
                <el-col :span="10">
                  <div
                    style="height: 590px"
                    data-aos="fade-up-right"
                    data-aos-duration="1500"
                    data-aos-offset="100">
                    <video
                      :src="tabsList[4].videoUrl"
                      controls
                      autoplay
                      loop
                      muted
                      style="
                        width: 100%;
                        border-radius: 5px;
                        height: 490px;
                        background: #000;
                      "></video>
                    <div
                      style="
                        color: #fff;
                        height: 93px;
                        background: #0058a7;
                        border-radius: 4px;
                        padding: 25px 10px;
                        font-size: 28px;
                        text-align-last: justify;
                      ">
                      南北贯通 东西互济 陆海联动 内外循环
                    </div>
                  </div>
                </el-col>
                <el-col :span="14">
                  <div
                    style="height: 590px; color: #fff"
                    data-aos="fade-up-left"
                    data-aos-duration="1500"
                    data-aos-offset="100">
                    <template v-for="(v, i) in testList3">
                      <div :key="i">
                        <div
                          v-if="v.menuName == menuName2"
                          style="
                            height: 493px;
                            padding: 6% 4%;
                            background: rgba(0, 73, 139, 0.8);
                            border-radius: 4px 4px 0px 0px;
                          "
                          :style="{ background: v.bgColor }">
                          <div
                            style="
                              font-size: 32px;
                              line-height: 32px;
                              font-weight: 600;
                            ">
                            {{ v.title }}
                          </div>
                          <div
                            style="
                              margin-top: 2%;
                              font-size: 24px;
                              line-height: 24px;
                              font-weight: 600;
                              letter-spacing: 2.9px;
                            ">
                            {{ v.engName }}
                          </div>
                          <p
                            style="
                              margin-top: 5%;
                              font-size: 18px;
                              font-weight: 400;
                              line-height: 28px;
                              text-indent: 2em;
                              color: #b6ceff;
                              text-align: justify;
                            ">
                            {{ v.menuDescription }}
                          </p>

                          <el-button
                            style="
                              background: transparent;
                              margin-top: 25px;
                              font-size: 14px;
                              color: #fff;
                            "
                            @click="
                              v.menuName == '多式联运创新产品'
                                ? $router.push(
                                    `/andHeader/product?type=创新产品`
                                  )
                                : $router.push(
                                    `/andHeader/multimodal?type=${tabsList[4].menuName}&name=${v.menuName}&show=1&menuId=${v.menuId}`
                                  )
                            "
                            >查看详情<img :src="icon1" alt=""
                          /></el-button>
                        </div>
                      </div>
                    </template>
                    <div
                      style="
                        height: 95px;
                        background: #0058a7;
                        border-radius: 0px 0px 4px 4px;
                        padding: 3%;
                      "
                      class="flex justify-between">
                      <div
                        @mouseover="handleColor(v)"
                        v-for="(v, i) in testList3"
                        :key="i">
                        <el-button
                          style="
                            font-size: 14px;
                            color: #fff;
                            height: 39px;
                            width: 180px;
                          "
                          :style="`background:${v.background};border-color:${v.borderColor}`"
                          >{{ v.menuName }}</el-button
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- 多式联运研发中心手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[4].bigPic})`,
            height: '655px',
          }"
          style="
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
          ">
          <div
            style="padding: 40px 15px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[4].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[4].title }}
            </div>
          </div>

          <div style="padding: 0 15px">
            <el-carousel ref="nop2" indicator-position="outside" arrow="never">
              <el-carousel-item v-for="(v, i) in testList3" :key="i">
                <div
                  class="relative"
                  @touchstart="start($event)"
                  @touchmove="move($event)"
                  data-aos="zoom-in"
                  @click="
                    v.menuName == '多式联运创新产品'
                      ? $router.push(`/andHeader/product?type=创新产品`)
                      : $router.push(
                          `/andHeader/multimodal?type=${tabsList[4].menuName}&name=${v.menuName}&show=1&menuId=${v.menuId}`
                        )
                  "
                  data-aos-easing="ease-out"
                  data-aos-duration="1500"
                  data-aos-offset="0">
                  <p class="hx-mobile text-center margin-bottom">
                    {{ v.title }}
                  </p>
                  <img height="230" width="100%" :src="v.bigPic" alt="" />
                  <p class="ds-mobile">{{ v.menuDescription }}</p>
                </div>
              </el-carousel-item>
            </el-carousel>

            <!-- 查看更多按钮 -->
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              class="light text-center"
              style="margin-top: 20px">
              <el-button
                style="
                  background: #24ad0f;
                  font-size: 14px;
                  color: #fff;
                  border-color: #24ad0f;
                "
                @click="
                  $router.push(
                    '/andHeader/multimodal?type=多式联运研发中心&name='
                  )
                "
                >查看更多
                <!-- <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt=""
                        /> -->
                <img height="20" :src="icon1" alt="" />
              </el-button>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="0"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- 数字化平台 -->
        <div
          v-if="showWeb"
          :style="{
            //backgroundImage: `url(${bg9})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat"
          ref="vantaRef">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              data-aos="zoomIn"
              data-aos-easing="ease-in-out"
              data-aos-duration="1500"
              data-aos-offset="0"
              style="padding: 5% 4% 4%">
              <span class="hexinTitle">{{ tabsList[5].menuName }}</span>
              <div class="margin-top text-20">
                {{ tabsList[5].title }}
              </div>
            </div>

            <div
              data-aos="slide-right"
              data-aos-duration="1500"
              data-aos-offset="0"
              class="flex justify-center"
              style="
                width: 100%;
                box-sizing: border-box;
                margin: auto;
                padding: 0 4% 1%;
              ">
              <div
                v-for="(c, f) in digitalList"
                :key="f"
                class="slide-card"
                :class="activeName == f ? 'active' : ''"
                @mouseover="mouseover(f)"
                :style="{
                  backgroundImage: `url(${c.indexBackground})`,
                }">
                <div>
                  <img class="logo" :src="c.logo" alt="" />
                  <span
                    class="logo_title"
                    :style="{
                      width:
                        c.menuName.indexOf('网络货运平台') > -1
                          ? '110px'
                          : '108px',
                    }"
                    >{{ c.menuName }}</span
                  >

                  <p class="desc_p">{{ c.subtitle }}</p>
                  <div class="descriptions">
                    <div class="descriptions-wrapper">
                      <p>
                        {{ c.description }}
                      </p>
                    </div>

                    <el-button
                      size="small"
                      style="
                        background: transparent;
                        font-size: 14px;
                        color: #fff;
                        margin-top: 50px;
                      "
                      @click="toDigitalize(c)"
                      >查看详情<img :src="icon1" alt=""
                    /></el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- 数字化平台手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            height: '655px',
          }"
          ref="vantaRef"
          style="
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
          ">
          <div
            style="padding: 40px 15px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[5].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[5].title }}
            </div>
          </div>

          <div style="padding: 0 20px">
            <el-carousel ref="nop3" indicator-position="outside" arrow="never">
              <el-carousel-item v-for="(v, i) in digitalList" :key="i">
                <!-- <p class="hx-mobile text-center margin-bottom">{{v.menuName}}</p> -->
                <div
                  class="relative digital-mobile"
                  @touchstart="start($event)"
                  @touchmove="move($event)"
                  data-aos="zoom-in"
                  data-aos-easing="ease-out"
                  data-aos-duration="1500"
                  data-aos-offset="0"
                  @click="toDigitalize(v)">
                  <img
                    class="margin-top-lg"
                    width="32"
                    height="32"
                    :src="v.logo"
                    alt="" />
                  <p class="digital-p margin-tb-lg">{{ v.menuName }}</p>
                  <img height="265" :src="v.smallPic" alt="" />
                </div>
              </el-carousel-item>
            </el-carousel>

            <!-- 查看更多按钮 -->
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              class="light text-center"
              style="margin-top: 20px">
              <el-button
                style="
                  background: #24ad0f;
                  font-size: 14px;
                  color: #fff;
                  border-color: #24ad0f;
                "
                @click="
                  $router.push('/andHeader/digitalize?type=数字化平台&name=')
                "
                >查看更多
                <!-- <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt=""
                        /> -->
                <img height="20" :src="icon1" alt="" />
              </el-button>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="0"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- ESG -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[6].bigPic})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="1500"
              style="padding: 5% 4% 4%">
              <span class="hexinTitle">{{ tabsList[6].menuName }}</span>
              <div class="margin-top text-20">
                {{ tabsList[6].title }}
              </div>
            </div>

            <div
              style="padding: 0 4% 1%; margin: 0 atuo"
              class="text-center text-white">
              <el-row :gutter="20">
                <el-col :span="8" v-for="(v, i) in esgList" :key="i">
                  <div
                    @click="
                      $router.push(
                        `/andHeader/ESG?type=${tabsList[6].menuName}&name=${v.menuName}&show=1&menuId=${v.menuId}`
                      )
                    "
                    style="height: 525px; border-radius: 8px; padding: 20% 10%"
                    :style="{
                      backgroundImage: `url(${v.smallPic})`,
                    }"
                    class="relative esg_hover"
                    :data-aos-delay="v.delay"
                    data-aos-offset="0"
                    data-aos="flip-right"
                    data-aos-duration="1500"
                    data-aos-easing="ease-out-quart">
                    <img :src="v.brandLogo" alt="" />
                    <p
                      class="margin-top-xl"
                      style="font-size: 30px; font-weight: 600">
                      {{ v.menuName }}
                    </p>
                    <p
                      class="margin-top-xl"
                      style="font-size: 18px; font-weight: 400">
                      {{ v.menuDescription }}
                    </p>
                    <el-button
                      style="
                        position: absolute;
                        transform: translate(-50%, -50%);
                        bottom: 5%;
                        background: transparent;
                        font-size: 14px;
                        color: #fff;
                      "
                      >查看详情<img :src="icon1" alt=""
                    /></el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- esg手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[6].bigPic})`,
            height: '600px',
          }"
          style="
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
          ">
          <div
            style="padding: 40px 15px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[6].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[6].title }}
            </div>
          </div>

          <div style="padding: 0 20px" class="esg-car">
            <el-carousel ref="nop" indicator-position="outside" arrow="never">
              <el-carousel-item v-for="(v, i) in esgList" :key="i">
                <div
                  class="relative esg-mobile"
                  @touchstart="start($event)"
                  @touchmove="move($event)"
                  :style="{ backgroundImage: `url(${v.smallPic})` }"
                  data-aos="zoom-in"
                  data-aos-easing="ease-out"
                  data-aos-duration="1500"
                  data-aos-offset="0"
                  @click="
                    $router.push(
                      `/andHeader/ESG?type=${tabsList[6].menuName}&name=${v.menuName}&show=1&menuId=${v.menuId}`
                    )
                  ">
                  <img
                    style="margin-top: 30%"
                    width="48"
                    height="48"
                    :src="v.brandLogo"
                    alt="" />
                  <p class="esg-p margin-tb-lg">{{ v.menuName }}</p>
                  <p class="esgp-mobile">{{ v.menuDescription }}</p>
                </div>
              </el-carousel-item>
            </el-carousel>

            <!-- 查看更多按钮 -->
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              class="light text-center"
              style="margin-top: 20px">
              <el-button
                style="
                  background: #24ad0f;
                  font-size: 14px;
                  color: #fff;
                  border-color: #24ad0f;
                "
                @click="$router.push('/andHeader/ESG?type=ESG&name=')"
                >查看更多
                <!-- <img
                          height="20"
                          class="black"
                          :src="icon_black"
                          alt=""
                        /> -->
                <img height="20" :src="icon1" alt="" />
              </el-button>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="0"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- 招贤纳士 -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[7].bigPic})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="1500"
              style="padding: 4% 4% 3%">
              <span class="hexinTitle">{{ tabsList[7].menuName }}</span>
              <div class="margin-top text-20">
                {{ tabsList[7].title }}
              </div>
            </div>

            <div class="text-white" style="padding: 0 4% 0%; margin: 0 atuo">
              <el-row :gutter="20">
                <el-col :span="6">
                  <div
                    class="recruit_div"
                    data-aos="slide-right"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1500"
                    data-aos-offset="300">
                    <div class="flex justify-between margin-bottom-xl">
                      <div class="recruit_title">
                        {{ tabsList[7].voList[1].menuName }}
                      </div>
                      <div
                        @click="toRecruit(tabsList[7], tabsList[7].voList[1])"
                        class="pointer"
                        style="
                          margin-top: 5px;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 14px;
                        ">
                        查看更多<img :src="icon1" alt="" />
                      </div>
                    </div>

                    <!-- 无限循环轮播 社会招聘3 -->
                    <div v-if="societyJob.length > 0">
                      <swiper
                        :options="swiperOption3"
                        ref="mySwiper3"
                        style="height: 500px">
                        <swiper-slide v-for="(v, i) in societyJob" :key="i">
                          <div
                            class="swiper_recruit"
                            style="padding: 4%"
                            @click="toJob(v)">
                            <div
                              style="
                                font-size: 18px;
                                line-height: 18px;
                                font-weight: 600;
                              ">
                              {{ v.jobName }}
                            </div>
                            <div
                              class="margin-top"
                              style="
                                font-size: 14px;
                                line-height: 14px;
                                font-weight: 400;
                                color: #b6ceff;
                              ">
                              <span>{{ v.department }}</span>
                              <span class="margin-lr-sm">|</span>
                              <span>{{ v.jobNum + "人" }}</span>
                            </div>
                            <div class="flex justify-between margin-top">
                              <div
                                style="
                                  font-size: 14px;
                                  line-height: 14px;
                                  font-weight: 400;
                                ">
                                {{ v.workplace }}
                              </div>
                              <div
                                style="
                                  font-size: 12px;
                                  line-height: 12px;
                                  font-weight: 400;
                                  color: #b6ceff;
                                ">
                                <span>发布时间：</span>
                                <span>{{ v.publishTime }}</span>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div
                    class="recruit_div"
                    data-aos="zoomIn"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1500"
                    data-aos-offset="300"
                    @click="
                      $router.push(
                        `/andHeader/recruit?type=${tabsList[7].menuName}&name=${tabsList[7].voList[2].menuName}&show=1&menuId=${tabsList[7].voList[2].menuId}`
                      )
                    ">
                    <div class="recruit_title">福利待遇</div>
                    <div
                      style="
                        margin-top: 20px;
                        margin-bottom: 30px;
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 400;
                        color: #b6ceff;
                      ">
                      <p>我们提供有竞争力的薪资待遇及完善丰富的福利体系，</p>
                      <p>
                        让每一位员工在中海通都可以放心的展翅翱翔，追逐梦想。
                      </p>
                    </div>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <div
                          class="welfare_hoverImg"
                          :style="{
                            backgroundImage: `url(${welfare1})`,
                          }"
                          style="
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            height: 420px;
                            border-radius: 5px;
                          "></div>
                      </el-col>
                      <el-col :span="12">
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <div
                              class="welfare_hoverImg"
                              :style="{
                                backgroundImage: `url(${welfare2})`,
                              }"
                              style="
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                                height: 200px;
                                border-radius: 5px;
                              "></div>
                          </el-col>
                          <el-col :span="12">
                            <div
                              class="welfare_hoverImg"
                              :style="{
                                backgroundImage: `url(${welfare3})`,
                              }"
                              style="
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                                height: 200px;
                                border-radius: 5px;
                              "></div>
                          </el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="24" style="margin-top: 20px">
                            <div
                              class="welfare_hoverImg"
                              :style="{
                                backgroundImage: `url(${welfare4})`,
                              }"
                              style="
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                                height: 200px;
                                border-radius: 5px;
                              "></div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div
                    class="recruit_div"
                    data-aos="slide-left"
                    data-aos-easing="easein-in-out"
                    data-aos-duration="1500"
                    data-aos-offset="300">
                    <div class="flex justify-between margin-bottom-xl">
                      <div class="recruit_title">
                        {{ tabsList[7].voList[0].menuName }}
                      </div>
                      <div
                        @click="toRecruit(tabsList[7], tabsList[7].voList[0])"
                        class="pointer"
                        style="
                          margin-top: 5px;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 14px;
                        ">
                        查看更多<img :src="icon1" alt="" />
                      </div>
                    </div>

                    <!-- 无限循环轮播 校园招聘-->
                    <div v-if="schoolJob.length > 0">
                      <swiper
                        :options="swiperOption3"
                        ref="mySwiper3"
                        style="height: 500px">
                        <swiper-slide v-for="(v, i) in schoolJob" :key="i">
                          <div
                            class="swiper_recruit"
                            style="padding: 4%"
                            @click="toJob(v)">
                            <div
                              style="
                                font-size: 18px;
                                line-height: 18px;
                                font-weight: 600;
                              ">
                              {{ v.jobName }}
                            </div>
                            <div
                              class="margin-top"
                              style="
                                font-size: 14px;
                                line-height: 14px;
                                font-weight: 400;
                                color: #b6ceff;
                              ">
                              <span>{{ v.department }}</span>
                              <span class="margin-lr-sm">|</span>
                              <span>{{ v.jobNum + "人" }}</span>
                            </div>
                            <div class="flex justify-between margin-top">
                              <div
                                style="
                                  font-size: 14px;
                                  line-height: 14px;
                                  font-weight: 400;
                                ">
                                {{ v.workplace }}
                              </div>
                              <div
                                style="
                                  font-size: 12px;
                                  line-height: 12px;
                                  font-weight: 400;
                                  color: #b6ceff;
                                ">
                                <span>发布时间：</span>
                                <span>{{ v.publishTime }}</span>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- 招贤纳士手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[7].smallPic})`,
            height: '710px',
          }"
          style="
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
          ">
          <div
            style="padding: 40px 15px 30px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[7].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[7].title }}
            </div>
          </div>

          <div
            style="padding: 0 20px"
            class="text-center"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="0">
            <div class="margin-bottom-lg">
              <el-button
                size="medium"
                style="background: #b9cee8; border-color: #b9cee8"
                @click="toRecruit(tabsList[7], tabsList[7].voList[0])"
                >{{ tabsList[7].voList[0].menuName }}</el-button
              >
              <el-button
                size="medium"
                style="background: #b9cee8; border-color: #b9cee8"
                @click="toRecruit(tabsList[7], tabsList[7].voList[1])"
                >{{ tabsList[7].voList[1].menuName }}</el-button
              >
            </div>

            <div
              class="zp-mobile"
              @click="
                $router.push(
                  `/andHeader/recruit?type=${tabsList[7].menuName}&name=${tabsList[7].voList[2].menuName}&show=1&menuId=${tabsList[7].voList[2].menuId}`
                )
              ">
              <p class="esgp-mobile" style="text-align: justify; margin: 0">
                {{
                  "我们提供有竞争力的薪资待遇及完善丰富的福利体系，让每一位员工在中海通都可以放心的展翅翱翔，追逐梦想。"
                }}
              </p>
              <el-row :gutter="10" class="margin-top">
                <el-col :span="12">
                  <div
                    class="welfare_hoverImg"
                    :style="{
                      backgroundImage: `url(${mobile1})`,
                    }"
                    style="
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      height: 176px;
                      border-radius: 5px;
                    "></div>
                </el-col>
                <el-col :span="12">
                  <div
                    class="welfare_hoverImg"
                    :style="{
                      backgroundImage: `url(${mobile2})`,
                    }"
                    style="
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      height: 176px;
                      border-radius: 5px;
                    "></div>
                </el-col>
              </el-row>
              <el-row class="margin-top-sm">
                <el-col :span="24">
                  <div
                    class="welfare_hoverImg"
                    :style="{
                      backgroundImage: `url(${mobile3})`,
                    }"
                    style="
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      height: 176px;
                      border-radius: 5px;
                    "></div>
                </el-col>
              </el-row>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="0"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- 联系我们 -->
        <div
          v-if="showWeb"
          :style="{
            backgroundImage: `url(${tabsList[8].bigPic})`,
            height: '900px',
          }"
          style="background-size: 100% 100%; background-repeat: no-repeat">
          <div style="height: 100%">
            <div
              class="text-center text-white"
              data-aos="zoom-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="1500"
              style="padding: 4% 4% 0%">
              <span
                class="hexinTitle"
                style="text-shadow: 0px 2px rgba(0, 0, 0, 0.5)"
                >{{ tabsList[8].menuName }}</span
              >
              <div
                class="margin-top text-20"
                style="text-shadow: 0px 2px rgba(0, 0, 0, 0.5)">
                {{ tabsList[8].title }}
              </div>
            </div>

            <div
              class="text-white"
              style="padding: 0 4% 1%; margin: 0 atuo"
              :style="{ padding: clientWidth < 1450 ? '0' : '0 4% 1%' }">
              <el-row :gutter="20">
                <el-col :lg="5" :xl="6">
                  <div
                    data-aos="slide-left"
                    data-aos-easing="easein-in-out"
                    data-aos-duration="1500"
                    data-aos-offset="100">
                    <div
                      v-if="changeColor"
                      class="map_div"
                      @mouseover="mouseSz"
                      @click="
                        $router.push(
                          `/andHeader/contactUs?type=${tabsList[8].menuName}&name=${tabsList[8].voList[0].menuName}`
                        )
                      ">
                      <div class="flex justify-between margin-bottom">
                        <div>
                          <img :src="duoduo" alt="" />
                          <span class="margin-left-sm">{{
                            tabsList[8].voList[0].menuName
                          }}</span>
                        </div>
                        <div>
                          <img :src="icon1" alt="" />
                        </div>
                      </div>
                      <div style="font-weight: 400; margin-left: 25px">
                        <p>传真：{{ footerInfo.companyFax }}</p>
                        <p class="margin-top-sm">
                          Tel：{{ footerInfo.companyPhone }}
                        </p>
                        <p class="margin-top-sm">
                          邮箱：{{ footerInfo.companyEmail }}
                        </p>
                        <p class="margin-top-sm">
                          地址：{{ footerInfo.companyAddress }}
                        </p>
                      </div>
                    </div>

                    <!-- 第一页 -->
                    <template v-if="changeColor">
                      <template v-for="(item, index) in mapLeftList">
                        <div
                          class="map_div margin-top-sm"
                          :key="index"
                          @mouseover="mouseMapLeft(item.menuName)"
                          :style="{ background: item.background }"
                          @click="
                            $router.push(
                              `/andHeader/contactUs?type=${tabsList[8].menuName}&name=${item.menuName}`
                            )
                          ">
                          <div class="flex justify-between margin-bottom">
                            <div>
                              <img :src="icon_small" alt="" />
                              <span class="margin-lr-sm">{{
                                item.menuName
                              }}</span>
                              <span class="text-sm"
                                >(共{{ item.total }}个网点)</span
                              >
                            </div>
                            <div>
                              <img :src="icon1" alt="" />
                            </div>
                          </div>
                          <div
                            style="
                              font-weight: 400;
                              margin-left: 25px;
                              height: 62px;
                              overflow: hidden;
                            ">
                            <el-row :gutter="20">
                              <el-col
                                :span="12"
                                v-for="(v, i) in item.map"
                                :key="i"
                                class="web_col">
                                <span>{{ `${i}网点：${v}个` }}</span>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </template>
                    </template>

                    <!-- 第二页 -->
                    <template v-else>
                      <template v-for="(item, index) in mapLeftList2">
                        <div
                          class="map_div margin-top-sm"
                          :key="index"
                          @mouseover="mouseMapLeft(item.menuName)"
                          :style="{ background: item.background }">
                          <div class="flex justify-between margin-bottom">
                            <div>
                              <img :src="icon_small" alt="" />
                              <span class="margin-lr-sm">{{
                                item.menuName
                              }}</span>
                              <span class="text-sm"
                                >(共{{ item.total }}个网点)</span
                              >
                            </div>
                            <div>
                              <img :src="icon1" alt="" />
                            </div>
                          </div>
                          <div
                            style="
                              font-weight: 400;
                              margin-left: 25px;
                              height: 62px;
                              overflow: hidden;
                            ">
                            <el-row :gutter="20">
                              <el-col
                                :span="12"
                                v-for="(v, i) in item.map"
                                :key="i"
                                class="web_col">
                                <span>{{ `${i}网点：${v}个` }}</span>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </template>
                    </template>

                    <div class="flex justify-end margin-top-xl">
                      <div
                        @click="changeColor = !changeColor"
                        :style="{
                          background: changeColor ? '#24ad0f' : '#fff',
                        }"
                        style="
                          width: 50px;
                          height: 4px;
                          background: #24ad0f;
                          margin-right: 5px;
                          cursor: pointer;
                        "></div>
                      <div
                        @click="changeColor = !changeColor"
                        :style="{
                          background: !changeColor ? '#24ad0f' : '#fff',
                        }"
                        style="
                          width: 50px;
                          height: 4px;
                          background: #fff;
                          cursor: pointer;
                        "></div>
                    </div>
                  </div>
                </el-col>
                <el-col :lg="14" :xl="12">
                  <div
                    class="relative"
                    style="width: 873.2px; height: 700px"
                    data-aos="slide-left"
                    data-aos-easing="easein-in-out"
                    data-aos-duration="1500"
                    data-aos-offset="100">
                    <img
                      style="position: absolute; z-index: 10"
                      :src="china_map"
                      alt="" />
                    <!-- 华北 -->
                    <div class="map_north" @click="mapMouseover('华北', 1)">
                      <img
                        :style="{
                          display: str.indexOf('华北') > -1 ? 'none' : 'inline',
                        }"
                        class="default"
                        :src="north"
                        alt="" />
                      <img
                        :style="{
                          display: str.indexOf('华北') > -1 ? 'inline' : 'none',
                        }"
                        class="green"
                        :src="north_green"
                        alt="" />
                    </div>
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 35%;
                        left: 63%;
                        z-index: 100;
                      ">
                      <img :src="north_icon" alt="" />
                    </div>
                    <!-- 东北 -->
                    <div class="map_northeast" @click="mapMouseover('东北', 1)">
                      <img
                        :style="{
                          display: str.indexOf('东北') > -1 ? 'none' : 'inline',
                        }"
                        class="default"
                        :src="northeast"
                        alt="" />
                      <img
                        :style="{
                          display: str.indexOf('东北') > -1 ? 'inline' : 'none',
                        }"
                        class="green"
                        :src="northeast_green"
                        alt="" />
                    </div>
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 18%;
                        left: 82%;
                        z-index: 100;
                      ">
                      <img :src="northeast_icon" alt="" />
                    </div>
                    <!-- 华东 -->
                    <div class="map_east" @click="mapMouseover('华东', 0)">
                      <img
                        :style="{
                          display: str.indexOf('华东') > -1 ? 'none' : 'inline',
                        }"
                        class="default"
                        :src="east"
                        alt="" />
                      <img
                        :style="{
                          display: str.indexOf('华东') > -1 ? 'inline' : 'none',
                        }"
                        class="green"
                        :src="east_green"
                        alt="" />
                    </div>
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 61%;
                        left: 76.5%;
                        z-index: 100;
                      ">
                      <img :src="east_icon" alt="" />
                    </div>
                    <!-- 华南 -->
                    <div class="map_south" @click="mapMouseover('华南', 0)">
                      <img
                        :style="{
                          display: str.indexOf('华南') > -1 ? 'none' : 'inline',
                        }"
                        class="default"
                        :src="south"
                        alt="" />
                      <img
                        :style="{
                          display: str.indexOf('华南') > -1 ? 'inline' : 'none',
                        }"
                        class="green"
                        :src="south_green"
                        alt="" />
                    </div>
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 81%;
                        left: 58%;
                        z-index: 100;
                      ">
                      <img :src="south_icon" alt="" />
                    </div>

                    <!-- 多多吉祥物 -->
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 81%;
                        left: 67%;
                        z-index: 100;
                      ">
                      <img :src="icon5" alt="" />
                    </div>
                    <!-- 海南岛屿 -->
                    <div
                      style="
                        position: absolute;
                        bottom: -2%;
                        right: 0%;
                        z-index: 100;
                      ">
                      <img :src="hainan" alt="" />
                    </div>
                    <!-- 华中 -->
                    <div class="map_centra" @click="mapMouseover('华中', 0)">
                      <img
                        :style="{
                          display: str.indexOf('华中') > -1 ? 'none' : 'inline',
                        }"
                        class="default"
                        :src="centra"
                        alt="" />
                      <img
                        :style="{
                          display: str.indexOf('华中') > -1 ? 'inline' : 'none',
                        }"
                        class="green"
                        :src="centra_green"
                        alt="" />
                    </div>
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 60%;
                        left: 64%;
                        z-index: 100;
                      ">
                      <img :src="centra_icon" alt="" />
                    </div>
                    <!-- 西北 -->

                    <div class="map_northwest" @click="mapMouseover('西北', 1)">
                      <img
                        :style="{
                          display: str.indexOf('西北') > -1 ? 'none' : 'inline',
                        }"
                        class="default"
                        :src="northwest"
                        alt="" />
                      <img
                        :style="{
                          display: str.indexOf('西北') > -1 ? 'inline' : 'none',
                        }"
                        class="green"
                        :src="northwest_green"
                        alt="" />
                    </div>
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 37%;
                        left: 28%;
                        z-index: 100;
                      ">
                      <img :src="northwest_icon" alt="" />
                    </div>
                    <!-- 西南 -->
                    <div class="map_southwest" @click="mapMouseover('西南', 0)">
                      <img
                        :style="{
                          display: str.indexOf('西南') > -1 ? 'none' : 'inline',
                        }"
                        class="default"
                        :src="southwest"
                        alt="" />
                      <img
                        :style="{
                          display: str.indexOf('西南') > -1 ? 'inline' : 'none',
                        }"
                        class="green"
                        :src="southwest_green"
                        alt="" />
                    </div>
                    <div
                      class="pointer"
                      style="
                        position: absolute;
                        top: 59%;
                        left: 26%;
                        z-index: 100;
                      ">
                      <img :src="southwest_icon" alt="" />
                    </div>
                  </div>
                </el-col>
                <el-col :lg="5" :xl="6">
                  <div
                    data-aos="slide-left"
                    data-aos-easing="easein-in-out"
                    data-aos-duration="1500"
                    data-aos-offset="100">
                    <template v-if="str">
                      <template>
                        <div class="map_div2">
                          <div class="margin-bottom">
                            <img :src="icon_big" alt="" />
                            <span class="margin-left-sm text-xl">{{
                              str
                            }}</span>
                          </div>

                          <div v-if="mapList.length > 0">
                            <swiper
                              :options="swiperOption_map"
                              ref="mySwiper4"
                              style="height: 500px">
                              <swiper-slide
                                v-for="(v, i) in mapList.slice(0, 10)"
                                :key="i">
                                <div class="map_list">
                                  <div class="margin-bottom">
                                    <img :src="icon4" alt="" />
                                    <span
                                      style="font-weight: 600"
                                      class="margin-left-sm"
                                      >{{ v.subsidiaryName }}</span
                                    >
                                  </div>
                                  <p class="map_item_p">
                                    区域负责人：{{ v.principal }}
                                  </p>
                                  <p class="map_item_p">
                                    Tel ：{{ v.principalPhone }}
                                  </p>
                                  <p class="map_item_p">
                                    地址：{{ v.subsidiaryAddress }}
                                  </p>
                                </div>
                              </swiper-slide>
                            </swiper>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="10"
            style="position: relative; top: -18px"></DividingLine>
        </div>
        <!-- 联系我们手机端 -->
        <div
          v-if="!showWeb"
          :style="{
            backgroundImage: `url(${tabsList[8].bigPic})`,
            height: '750px',
          }"
          style="
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
          ">
          <div
            style="padding: 40px 15px 30px"
            class="text-center text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500">
            <span class="hexinTitle-mobile">{{ tabsList[8].menuName }}</span>
            <div class="margin-top titles-mobile">
              {{ tabsList[8].title }}
            </div>
          </div>

          <div style="padding: 0 20px">
            <el-row>
              <el-col
                :span="24"
                data-aos="zoom-in"
                data-aos-easing="ease-out"
                data-aos-duration="1500"
                data-aos-offset="0">
                <div class="aboutus-mobile text-white">
                  <div
                    class="flex justify-between margin-bottom-sm"
                    @click="
                      $router.push(
                        `/andHeader/contactUs?type=${tabsList[8].menuName}&name=${tabsList[8].voList[0].menuName}`
                      )
                    ">
                    <p class="text-df">
                      {{ this.tabsList[8].voList[0].menuName }}
                    </p>
                    <div>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                  <div class="margin-bottom-xs">
                    <span class="margin-right-xs">传真：</span>
                    <span>{{ footerInfo.companyFax }}</span>
                  </div>
                  <div class="margin-bottom-xs">
                    <span class="margin-right-sm">Tel ：</span>
                    <span>{{ footerInfo.companyPhone }}</span>
                  </div>
                  <div class="margin-bottom-xs">
                    <span class="margin-right-xs">邮箱：</span>
                    <span>{{ footerInfo.companyEmail }}</span>
                  </div>
                  <div>
                    <span class="margin-right-xs">地址：</span>
                    <span>{{ footerInfo.companyAddress }}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="margin-top-sm">
              <el-col
                :span="12"
                v-for="(v, i) in tabsList[8].voList.slice(1, 12)"
                :key="i"
                data-aos="zoom-in"
                data-aos-easing="ease-out"
                data-aos-duration="1500"
                data-aos-offset="0">
                <div
                  style="height: 100px"
                  class="aboutus-mobile text-white margin-bottom-sm"
                  @click="
                    $router.push(
                      `/andHeader/contactUs?type=${tabsList[8].menuName}&name=${v.menuName}`
                    )
                  ">
                  <div class="flex justify-between margin-bottom-sm">
                    <p class="text-df">{{ v.menuName }}</p>
                    <div>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>

                  <el-row>
                    <el-col
                      :span="8"
                      v-for="(c, f) in v.map"
                      :key="f"
                      class="margin-bottom-xs">
                      <p
                        :title="f"
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        ">
                        {{ f }}
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
          <DividingLine
            data-aos="zoom-in"
            data-aos-easing="ease-out"
            data-aos-duration="1500"
            data-aos-offset="0"
            style="position: absolute; bottom: 0; width: 100%"></DividingLine>
        </div>

        <!-- 底部 -->
        <div
          v-if="scrollHeight > 750 && !showNav"
          :style="{
            position: 'fixed',
            bottom: '100px',
            right: showWeb ? '50px' : '15px',
            cursor: 'pointer',
            zIndex: '1000',
          }"
          @click="toTop">
          <img width="70" :src="top" alt="" />
        </div>
        <FooterInfo
          :tabsList.sync="tabsList"
          :footerInfo.sync="footerInfo"></FooterInfo>
      </div>
    </div>
  </div>
</template>

<script>
import { wexinShare } from "@/utils/wxsdk.js";
import "aos/dist/aos.css";
import AOS from "aos/dist/aos.js";
import * as services from "@/api/website";
import * as THREE from "three";
import DOTS from "vanta/src/vanta.dots";
import BIRDS from "vanta/src/vanta.birds";
import NET from "vanta/src/vanta.net";
import { debounce } from "@/utils/function";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import wx from "@/assets/images/wx.png";
import link from "@/assets/images/link.png";
import top from "@/assets/images/top.png";
import mobile1 from "@/assets/images/mobile1.png";
import mobile2 from "@/assets/images/mobile2.png";
import mobile3 from "@/assets/images/mobile3.png";
import icon1 from "@/assets/images/icon1.png";
import icon2 from "@/assets/images/icon2.png";
import icon3 from "@/assets/images/icon3.png";
import icon4 from "@/assets/images/icon4.png";
import icon_big from "@/assets/images/icon_big.png";
import button from "@/assets/images/button.png";
import button_hover from "@/assets/images/button_hover.png";
import china_map from "@/assets/images/china_map.png";
import north from "@/assets/images/north.png";
import northeast from "@/assets/images/northeast.png";
import east from "@/assets/images/east.png";
import centra from "@/assets/images/centra.png";
import south from "@/assets/images/south.png";
import northwest from "@/assets/images/northwest.png";
import southwest from "@/assets/images/southwest.png";
import north_green from "@/assets/images/north_green.png";
import northeast_green from "@/assets/images/northeast_green.png";
import east_green from "@/assets/images/east_green.png";
import centra_green from "@/assets/images/centra_green.png";
import south_green from "@/assets/images/south_green.png";
import northwest_green from "@/assets/images/northwest_green.png";
import southwest_green from "@/assets/images/southwest_green.png";
import north_icon from "@/assets/images/north_icon.png";
import northeast_icon from "@/assets/images/northeast_icon.png";
import east_icon from "@/assets/images/east_icon.png";
import centra_icon from "@/assets/images/centra_icon.png";
import south_icon from "@/assets/images/south_icon.png";
import northwest_icon from "@/assets/images/northwest_icon.png";
import southwest_icon from "@/assets/images/southwest_icon.png";
import hainan from "@/assets/images/hainan.png";
import more from "@/assets/images/more.png";
import more_green from "@/assets/images/more_green.png";
import news from "@/assets/images/news.png";
import news_green from "@/assets/images/news_green.png";
import blue from "@/assets/images/blue.png";
import icon_small from "@/assets/images/icon_small.png";
import welfare1 from "@/assets/images/welfare1.png";
import welfare2 from "@/assets/images/welfare2.png";
import welfare3 from "@/assets/images/welfare3.png";
import welfare4 from "@/assets/images/welfare4.png";
import duoduo from "@/assets/images/duoduo.png";
import icon5 from "@/assets/images/icon5.png";
import icon_green from "@/assets/images/icon_green.png";
import fazhan from "@/assets/images/fazhan.png";
import web from "@/assets/images/web.png";
import rongyu from "@/assets/images/rongyu.png";
import wenhua from "@/assets/images/wenhua.png";
import shiming from "@/assets/images/shiming.png";
import yuanjing from "@/assets/images/yuanjing.png";
import hexin from "@/assets/images/hexin.png";
import duoduo2 from "@/assets/images/duoduo2.png";
import icon_black from "@/assets/images/icon_black.png";
import titleBg from "@/assets/images/titleBg.png";
import HeaderInfo from "@/views/components/HeaderInfo/index";
import FooterInfo from "@/views/components/FooterInfo/index";
import DividingLine from "@/views/components/DividingLine/index";
import { WOW } from "wowjs";
export default {
  name: "sinoceanView",
  components: {
    HeaderInfo,
    FooterInfo,
    DividingLine,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      showNav: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      mobile1: mobile1,
      mobile2: mobile2,
      mobile3: mobile3,
      showWeb: false,
      debounce,
      titleStyle: true,
      digitalList: [],
      menuName2: "中海通多式联运创新",
      top: top,
      wx: wx,
      link: link,
      aboutMouse: false,
      societyJob: [], //社会招聘
      schoolJob: [], //校园招聘
      aboutList: [
        {
          name: "发展历程",
          icon: fazhan,
          delay: "40",
          delayLeft: "50",
        },
        {
          name: "网络布局",
          icon: web,
          delay: "60",
          delayLeft: "70",
        },
        {
          name: "资质荣誉",
          icon: rongyu,
          delay: "80",
          delayLeft: "90",
        },
        {
          name: "企业文化",
          icon: wenhua,
          delay: "100",
          delayLeft: "110",
        },
        {
          name: "使命",
          icon: shiming,
          delay: "120",
          delayLeft: "130",
        },
        {
          name: "愿景",
          icon: yuanjing,
          delay: "140",
          delayLeft: "150",
        },
        {
          name: "核心价值观",
          icon: hexin,
          delay: "160",
          delayLeft: "170",
        },
        {
          name: "多多系列",
          icon: duoduo2,
          delay: "180",
          delayLeft: "190",
        },
      ],
      icon_black: icon_black,
      news_green: news_green,
      changeColor: true,
      activeName: 1, //默认显示第一个
      // 创新产品轮播 1--3
      swiperOption: {
        speed: 5000, // 匀速时间
        freeMode: true,
        loop: true,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          reverseDirection: true, // 开启反向轮播
          pauseOnMouseEnter: true,
        },
        slidesPerView: 6, // 显示数量
        spaceBetween: 20, // 间距
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        loopedSlides: 14, // 比你页面显示的数量大于2就好
        updateOnWindowResize: false, // 分辨率改变时，防止样式错乱加上！
      },
      // 创新产品轮播 2
      swiperOption2: {
        speed: 5000, // 匀速时间
        freeMode: true,
        loop: true,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          reverseDirection: false, // 开启反向轮播
          pauseOnMouseEnter: true,
        },
        slidesPerView: 6, // 显示数量
        spaceBetween: 20, // 间距
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        loopedSlides: 14, // 比你页面显示的数量大于2就好
        updateOnWindowResize: false, // 分辨率改变时，防止样式错乱加上！
      },
      // 招聘轮播
      swiperOption3: {
        spaceBetween: 20, // 间距
        updateOnWindowResize: false,
        normalizeSlideIndex: true,
        loopFillGroupWithBlank: true,
        slidesPerView: 4,
        loopedSlides: 12, // 比你页面显示的数量大于2就好
        speed: 5000, //匀速时间，根据实际情况调节
        freeMode: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        direction: "vertical",
        loop: true,
      },
      // 联系我们-网点信息轮播
      swiperOption_map: {
        spaceBetween: 0, // 间距
        updateOnWindowResize: false,
        normalizeSlideIndex: true,
        loopFillGroupWithBlank: true,
        slidesPerView: 4,
        loopedSlides: 6, // 比你页面显示的数量大于2就好
        speed: 5000, //匀速时间，根据实际情况调节
        freeMode: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        direction: "vertical",
        loop: true,
      },
      swiperOption_mobile: {
        speed: 5000, // 匀速时间
        freeMode: true,
        loop: true,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          reverseDirection: false, // 开启反向轮播
          pauseOnMouseEnter: true,
        },
        slidesPerView: 2, // 显示数量
        spaceBetween: 80, // 间距
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        loopedSlides: 40, // 比你页面显示的数量大于2就好
        updateOnWindowResize: false, // 分辨率改变时，防止样式错乱加上！
      },
      carList: [],
      tableData: [],
      swiperList: [],
      newsList: [],
      testList3: [],
      esgList: [],
      mapLeftList: [],
      mapLeftList2: [],
      mapList: [],
      // screenHeight: 0,
      scrollHeight: 0,
      currentScroll: 0,
      screenWeight: 0, // 屏幕宽度
      screenHeight: 0, // 屏幕高度
      index: 1, // 用于判断翻页
      imgclass: "",
      clientWidth: "",
      icon_green: icon_green,
      button_hover: button_hover,
      button: button,
      blue: blue,
      titleBg: titleBg,
      duoduo: duoduo,
      hainan: hainan,
      icon5: icon5,
      icon_small: icon_small,
      china_map: china_map,
      north: north,
      northeast: northeast,
      east: east,
      centra: centra,
      south: south,
      northwest: northwest,
      southwest: southwest,
      north_green: north_green,
      northeast_green: northeast_green,
      east_green: east_green,
      centra_green: centra_green,
      south_green: south_green,
      northwest_green: northwest_green,
      southwest_green: southwest_green,
      north_icon: north_icon,
      northeast_icon: northeast_icon,
      east_icon: east_icon,
      centra_icon: centra_icon,
      south_icon: south_icon,
      northwest_icon: northwest_icon,
      southwest_icon: southwest_icon,
      more: more,
      more_green: more_green,
      icon1: icon1,
      icon2: icon2,
      icon3: icon3,
      icon4: icon4,
      icon_big: icon_big,
      welfare1: welfare1,
      welfare2: welfare2,
      welfare3: welfare3,
      welfare4: welfare4,
      str: "西北区域",
      bgTopStyle: "-5px",
      tabsList: [
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
        {
          menuName: "", //菜单名
          title: "", //标题
          bigPic: "", //背景图
          videoUrl: "", //视频封面
          videoCover: "", //视频
          menuContent: "", //内容
          voList: [
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
            {
              menuName: "",
              menuDescription: "",
              brandLogo: "",
              indexBackground: "",
            },
          ], //二级菜单
        },
      ],
      vantaEffect: null,
      footerInfo: {},
      aboutDetail: {},
      wxObj: {},
    };
  },
  watch: {
    clientWidth: {
      handler(val) {
        this.imgclass = val < 1920 ? "" : "margin-lr-xl";
        this.bgTopStyle = val > 1920 ? "-5px" : "34px";
        this.titleStyle = val >= 1920 ? true : false;
        this.showWeb = val > 780 ? true : false;
        if (val < 1920) {
          this.swiperOption.slidesPerView = 4;
          this.swiperOption.spaceBetween = 0;
          this.swiperOption2.slidesPerView = 4;
          this.swiperOption2.spaceBetween = 0;
        }
      },
    },
    currentScroll: {
      handler(newVal, oldVal) {
        if (newVal < oldVal && oldVal - newVal >= 400) {
          // setTimeout(() => {
          //   new this.$wow.WOW().init();
          // }, 1500);
          // 防抖
          // this.debounce(new this.$wow.WOW().init(), 1000);
        }
      },
    },
    // screenHeight: {
    //   handler(val) {
    //     this.screenHeight = val;
    //   },
    // },
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.getMenuDetail();

    this.screenWeight = document.documentElement.clientWidth;
    this.screenHeight = document.documentElement.clientHeight;
    window.onscroll = this.debounce(this.scroll, 100);
    // 获取屏幕宽度
    this.clientWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.clientWidth = document.body.clientWidth;
        this.screenHeight = document.documentElement.clientHeight;
      })();
    };
    this.$nextTick(() => {
      new this.$wow.WOW({
        live: false,
      }).init();
      AOS.init({});
      this.vantaEffect = NET({
        el: this.$refs.vantaRef,
        THREE: THREE,
      });
      // 这里重新设置样式
      this.vantaEffect.setOptions({
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        color: 0x6c3fff,
        backgroundColor: 0xd1e52,
        points: 12.0,
      });
    });
  },
  beforeDestroy() {
    window.onscroll = null;
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
  methods: {
    async getMenuDetail() {
      let res = await services.getMenuDetail(2);
      if (res.code == 200) {
        this.aboutDetail = res.data;
      }
    },
    childByValue(val) {
      console.log("val: ", val);
      this.showNav = val;
    },
    start(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    move(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      var nowtime = new Date().getTime();
      if (this.startTime == undefined || nowtime > this.startTime) {
        if (this.startX - this.moveX <= 0) {
          // 右滑触发
          this.prev();
          return false;
        } else {
          this.next();
          return false;
        }
      }
    },
    prev() {
      this.$refs.nop.prev();
      this.$refs.nop1.prev();
      this.$refs.nop2.prev();
      this.$refs.nop3.prev();
      this.startTime = new Date().getTime() + 500;
    },
    next() {
      this.$refs.nop.next();
      this.$refs.nop1.next();
      this.$refs.nop2.next();
      this.$refs.nop3.next();
      this.startTime = new Date().getTime() + 500;
    },
    // 跳转到招聘子页面
    toRecruit(val, item) {
      let obj = {};
      for (let i of this.tabsList) {
        if (i.menuName == val.menuName) {
          obj = { type: i.menuName, path: i.path, menuType: i.menuType };
        }
      }
      localStorage.setItem("routeObj", JSON.stringify(obj)); //存
      this.$router.push(
        `/andHeader/${val.path}?type=${val.menuName}&name=${item.menuName}&show=1&menuId=${item.menuId}`
      );
    },
    // 跳转到新闻详情
    async toDetail(v, flag) {
      let path = "";
      let menuName = "";
      if (flag == 1) {
        localStorage.setItem("routeObj", JSON.stringify(this.tabsList[2])); //存
        path = this.tabsList[2].path;
        menuName = this.tabsList[2].menuName;
      } else if (flag == 0) {
        localStorage.setItem("routeObj", JSON.stringify(this.tabsList[1])); //存
        path = this.tabsList[1].path;
        menuName = this.tabsList[1].menuName;
      } else if (flag == 2) {
        localStorage.setItem("routeObj", JSON.stringify(this.tabsList[3])); //存
        path = this.tabsList[3].path;
        menuName = this.tabsList[3].menuName;
      }

      localStorage.setItem("btnName", v.menuName); //存
      let detailId = "";
      if (v.menuType == 2) {
        detailId = v.jobId;
      } else if (v.menuType == 4) {
        detailId = v.videoId;
      } else if (v.menuType == 6) {
        detailId = v.articleId;
      } else {
        detailId = v.menuId;
      }
      this.getRecommendList(v.menuId, v.parentId, v.menuType, detailId);

      // 判断是否是新闻文章
      if (v.articleId) {
        localStorage.setItem("newsObj", JSON.stringify(v)); //存
      } else {
        let res = await services.getSonListEnable({ menuId: v.menuId });
        if (res.code == 200) {
          localStorage.setItem("newsObj", JSON.stringify(res.data[0])); //存
        }
      }
      this.$router.push({
        name: "newsDetail",
        query: {
          id: v.articleId || detailId,
          btnName: v.menuName,
          menuName: menuName,
          path: path,
          menuType: v.menuType || this.tabsList[3].voList[0].menuType,
          parentId: v.parentId || this.tabsList[3].voList[0].parentId,
          menuId2: v.menuId || this.tabsList[3].voList[0].menuId,
        },
      });
    },

    // 获取新闻推荐列表
    async getRecommendList(menuId, parentId, menuType, id) {
      let res = await services.getRecommendList({
        menuId: menuId || this.tabsList[3].voList[0].menuId,
        parentId: parentId || this.tabsList[3].voList[0].parentId,
        menuType: menuType || this.tabsList[3].voList[0].menuType,
        detailId: id,
      });
      if (res.code == 200) {
        localStorage.setItem("newsList", JSON.stringify(res.data)); //存
      }
    },
    async getArticle() {
      let res = await services.getArticle({});
      if (res.code == 200) {
        this.newsList = res.rows;
        let time = 0;
        for (let i of this.newsList) {
          i["img"] = news;
          i["img2"] = news_green;
          time += 50;
          i["delay"] = time;
        }
      }
    },
    // 获取招聘信息
    async getJob() {
      let menuId1 = "";
      let menuId2 = "";
      for (let i of this.tabsList[7].voList) {
        if (i.menuName == "社会招聘") {
          menuId1 = i.menuId;
        } else if (i.menuName == "校园招聘") {
          menuId2 = i.menuId;
        }
      }
      let res = await services.getJob({
        menuId: menuId1,
      });
      if (res.code == 200) {
        this.societyJob = res.rows; //招贤纳士-社会招聘
      }
      let res2 = await services.getJob({
        menuId: menuId2,
      });
      if (res2.code == 200) {
        this.schoolJob = res2.rows; //招贤纳士-校园招聘
      }
    },
    // 招聘跳转详情
    toJob(val) {
      if (val.menuName == "社会招聘") {
        localStorage.setItem("newsList", JSON.stringify(this.societyJob)); //存
      } else if (val.menuName == "校园招聘") {
        localStorage.setItem("newsList", JSON.stringify(this.schoolJob)); //存
      }
      localStorage.setItem("btnName", val.menuName); //存
      localStorage.setItem("newsObj", JSON.stringify(val)); //存
      this.$router.push({
        name: "newsDetail",
      });
    },
    // 获取网点信息
    async getSubsidiary() {
      let arr = [];
      let menuId = "";
      this.mapList = [];
      for (let i of this.tabsList) {
        if (i.menuName == "联系我们") {
          arr = i.voList;
        }
      }
      for (let i of arr) {
        if (this.str == i.menuName) {
          menuId = i.menuId;
        }
      }
      let res = await services.getSubsidiary({
        menuId: menuId,
      });
      if (res.code == 200) {
        this.mapList = res.rows;
      }
    },
    getShareWx() {
      //请求微信配置参数接口（获取签名），由后台给接口给
      var urls = window.location.href.split("#")[0];
      //看后台请求接口是get/post
      services
        .getWxConfig({
          shareUrl: urls,
        })
        .then((res) => {
          if (res.code == 200) {
            //微信加签
            let obj = {
              appId: "wxe2cd072448254e57",
              nonceStr: res.data.nonceStr,
              signature: res.data.signature,
              timestamp: res.data.timestamp,
              jsApiList: [
                "checkJsApi",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ],
            };
            let imgUrl = this.wxObj.wxSharePic;
            let title = this.wxObj.menuName;
            let desc = "";
            let link = window.location.href;

            // link = encodeURIComponent(link);

            //分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
            // var url = window.location.href.split('#')[0];
            // var shareWxLink = encodeURIComponent(url);加密
            let shareData = {
              title: title, // 分享标题
              desc: desc, //描述
              link: link, //链接
              imgUrl: imgUrl, // 分享图标
            };
            //引用
            wexinShare(obj, shareData);

            if (!this.showWeb) {
              // this.$message.warning("请点击右上角 ... 分享给好友或朋友圈");
            }
          } else {
            // this.$toast.warn('获取sdk参数失败！');
          }
        });
    },
    // 获取首页的路由菜单信息
    async getInfo() {
      let res = await services.getInfoFilter();
      if (res.code == 200) {
        let arr = res.data[0].voList;
        this.wxObj = res.data[0];
        this.tabsList = arr;
        for (let k of this.tabsList) {
          k["type"] = k.menuName;
          k["btnBackground"] = "";
          k["menuList"] = k.voList;
          for (let c of k.menuList) {
            c["name"] = c.menuName;
          }
        }
        localStorage.setItem("tabsList", JSON.stringify(this.tabsList)); //存
        this.swiperList = this.tabsList[2].voList; //创新产品
        this.testList3 = this.tabsList[4].voList; //多式联运研发中心
        this.esgList = this.tabsList[6].voList; //esg
        this.mapLeftList = this.tabsList[8].voList.slice(1, 4);
        this.mapLeftList2 = this.tabsList[8].voList.slice(4, 8);

        // 手机端核心业务-list
        for (let i = 0; i < this.tabsList[1].voList.length; i += 4) {
          this.carList.push({
            list: this.tabsList[1].voList.slice(i, i + 4),
          });
        }
        console.log("this.carList: ", this.carList);

        for (let i of this.mapLeftList) {
          if (i.menuName == "西北区域") {
            i["background"] =
              "linear-gradient(to top, rgba(36, 173, 15, 0.58) , rgba(29, 135, 206))";
          } else {
            i["background"] = "";
          }
        }
        for (let i of this.mapLeftList2) {
          i["background"] = "";
        }
        for (let [index, val] of this.testList3.entries()) {
          if (index == 0) {
            val["background"] = "#24ad0f";
            val["borderColor"] = "#24ad0f";
            val["bgColor"] = "rgba(0, 73, 139, 0.8)";
          } else {
            val["background"] = "transparent";
            val["borderColor"] = "#fff";
            val["bgColor"] = "rgba(0, 73, 139, 0.8)";
          }
        }
        let time = "";
        for (let i of this.esgList) {
          time += 200;
          i["delay"] = time;
        }
        this.getArticle();
        this.getJob();
        this.getSubsidiary();
        this.getDigital();
        this.getConfig();
        this.getShareWx();
      }
    },
    async getConfig() {
      let res = await services.getConfig();
      if (res.code == 200) {
        this.footerInfo = res.rows[0];
        localStorage.setItem("footerInfo", JSON.stringify(this.footerInfo)); //存
      }
    },
    async getDigital() {
      let res = await services.getDigital();
      if (res.code == 200) {
        this.digitalList = res.data;
      }
    },
    toDigitalize(c) {
      let obj = {};
      for (let i of this.tabsList) {
        if (i.menuName == "数字化平台") {
          obj = { type: i.menuName, path: i.path, menuType: i.menuType };
        }
      }
      localStorage.setItem("routeObj", JSON.stringify(obj)); //存
      this.$router.push(
        `/andHeader/digitalize?type=数字化平台&name=${c.menuName}&show=0`
      );
    },
    scroll() {
      this.scrollHeight = document.documentElement.scrollTop;
      this.currentScroll = window.pageYOffset;
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 选中总部
    mouseSz() {
      this.str = "";
      this.mapList = [];
    },
    mouseMapLeft(type) {
      for (let i of this.mapLeftList) {
        i.background = "";
      }
      for (let i of this.mapLeftList2) {
        i.background = "";
      }
      this.str = type;
      this.mapList = [];
      setTimeout(() => {
        this.getSubsidiary();
      }, 500);
    },

    // 鼠标进入判断选中哪个区域
    mapMouseover(val, show) {
      this.changeColor = show;
      this.str = val;
      let list = this.changeColor ? this.mapLeftList : this.mapLeftList2;
      for (let i of list) {
        if (i.menuName.indexOf(val) > -1) {
          i.background =
            "linear-gradient(to top, rgba(36, 173, 15, 0.58) , rgba(29, 135, 206))";
        } else {
          i.background = "";
        }
      }
    },
    // 鼠标进入切换选中样式
    mouseover(val) {
      this.activeName = val;
    },
    // 改变按钮颜色
    handleColor(val) {
      this.menuName2 = val.menuName;
      for (let i of this.testList3) {
        if (val.menuName == i.menuName) {
          i.background = "#24ad0f";
          i.borderColor = "#24ad0f";
        } else {
          i.background = "transparent";
          i.borderColor = "#fff";
        }
      }
    },
    // 新闻中心-轮播图回调事件
    changeCarousel(val) {},
    clickCarousel(val) {},
  },
};
</script>
<style lang="less" scoped>
// 屏幕大于1200
@media screen and (min-width: 1200px) {
  /deep/.el-carousel__container {
    height: 525px;
    min-height: 400px;
  }
  .scroll-div {
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    padding-top: 120px;
    padding-left: 18px;
    padding-right: 18px;
    .scroll_title {
      font-size: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .scroll_title2 {
      font-size: 14px;
      color: #b6ceff;
    }
    &:hover {
      text-align: center;
      padding-top: 70px;
      padding-left: 15px;
      transform: scale(1.06);
      transition: 0.5s;
      .scroll_p {
        position: absolute;
        z-index: 100;
        width: 90%;
        p {
          color: #fff;
        }
        .scroll_title {
          font-weight: 600;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
    &:hover::before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
      background: linear-gradient(
        0deg,
        rgba(36, 173, 15, 0.58),
        rgba(14, 114, 255, 0.5)
      ) !important;
    }
  }
}
// 屏幕小于780
@media screen and (max-width: 780px) {
  /deep/.el-carousel__container {
    min-height: 400px;
  }
  .esg-car {
    /deep/.el-carousel__container {
      min-height: 350px;
    }
  }
  .scroll-div {
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    padding-top: 160px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    .scroll_title {
      font-size: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      color: #fff;
    }
    .scroll_title2 {
      font-size: 12px;
      color: #b6ceff;
      margin-top: 10px;
    }
    // &:hover {
    //   border-radius: 8px;
    //   text-align: center;
    //   padding-top: 80px;
    //   padding-left: 15px;
    //   transform: scale(1.03);
    //   transition: 0.5s;
    //   .scroll_p {
    //     position: absolute;
    //     z-index: 100;
    //     width: 90%;
    //     p {
    //       color: #fff;
    //     }
    //     .scroll_title {
    //       font-weight: 600;
    //       display: -webkit-box;
    //       -webkit-box-orient: vertical;
    //       -webkit-line-clamp: 3;
    //       overflow: hidden;
    //     }
    //   }
    // }
    // &:hover::before {
    //   content: " ";
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   opacity: 1;
    //   background: linear-gradient(
    //     0deg,
    //     rgba(36, 173, 15, 0.58),
    //     rgba(14, 114, 255, 0.5)
    //   ) !important;
    // }
  }
}
.aboutus-mobile {
  padding: 15px;
  border-radius: 10px;
  height: 150px;
  // background: #195594;
  background-image: url("./aboutView/img/us.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.zp-mobile {
  background: #195594;
  height: 490px;
  opacity: 0.9;
  border-radius: 10px;
  padding: 20px 15px;
}
.esgp-mobile {
  opacity: 0.8;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-5;
  font-weight: 400;
  color: #fff;
  line-height: 24px;
  margin: 0 25px;
}
.esg-mobile {
  height: 340px;
  border-radius: 10px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
}
.esg-p {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-8;
  font-weight: 700;
  color: #fff;
  line-height: 28px;
}
.digital-mobile {
  background-image: url("./aboutView/img/digital.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 373px;
  border-radius: 10px;
  text-align: center;
}
.digital-p {
  color: #fff;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-8;
  font-weight: 700;
  line-height: 16px;
}
.ds-mobile {
  margin: 15px 0;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-5;
  font-weight: 400;
  text-indent: 2em;
  text-align: justify;
  color: #fff;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.news_hover_div {
  margin-top: 20px;
  padding: 0px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      0deg,
      rgba(36, 173, 15, 0.1),
      rgba(255, 255, 255, 0)
    );
    border-radius: 8px;

    .news_title_p {
      color: #24ad0f;
    }
  }
}
.news_title_p {
  margin-bottom: 5px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 400;
  // color: #333;
  color: #fff;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.news-mobile {
  position: absolute;
  bottom: 10px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-family: PingFang SC, PingFang SC-5;
}
/deep/.el-button {
  line-height: inherit;
}
.hx-mobile {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-6;
  color: #fff;
  line-height: 14px;
}
// 福利待遇-悬停背景图放大
.welfare_hoverImg {
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    background-size: 110% !important;
    background-position: center;
    background-repeat: no-repeat;
  }
}
// 了解更多-悬停图标切换
.hover_more {
  .white {
    display: inline-block;
  }
  .green {
    display: none;
  }
  &:hover {
    .white {
      display: none;
    }
    .green {
      display: inline-block;
    }
  }
}
// 查看-悬停切换
.btn_hover {
  &:hover {
    color: #24ad0f !important;
    .white {
      display: none;
    }
    .green {
      display: inline-block;
    }
  }
  .white {
    display: inline-block;
  }
  .green {
    display: none;
  }
}
.map_list {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}
.map_item_p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}
// 联系我们-右边-网点列表
.map_div2 {
  max-height: 600px;
  height: 600px;
  // overflow: auto;
  overflow-y: scroll;
  font-size: 14px;
  padding: 20px;
  // opacity: 0.9;
  background: #1d87ce;
  border: 1px solid #00f5ff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(104, 252, 255, 0.5);
}
// 七大区域-悬停切换效果
.map_southwest {
  position: absolute;
  cursor: pointer;
  bottom: 9%;
  left: 4%;
  z-index: 10;
  // opacity: 0.7;
  .green {
    display: none;
  }
  .default {
    display: inline;
  }
  &:hover {
    .green {
      display: inline;
    }
    .default {
      display: none;
    }
  }
}
.map_northwest {
  position: absolute;
  cursor: pointer;
  top: 9.5%;
  left: 1%;
  z-index: 10;
  // opacity: 0.7;
  .green {
    display: none;
  }
  .default {
    // display: block;
    display: inline;
  }
  &:hover {
    .green {
      // display: block;
      display: inline;
    }
    .default {
      display: none;
    }
  }
}
.map_centra {
  position: absolute;
  cursor: pointer;
  bottom: 17.5%;
  left: 57.5%;
  z-index: 10;
  // opacity: 0.7;
  .green {
    display: none;
  }
  .default {
    // display: block;
    display: inline;
  }
  &:hover {
    .green {
      // display: block;
      display: inline;
    }
    .default {
      display: none;
    }
  }
}
.map_south {
  position: absolute;
  cursor: pointer;
  bottom: 1px;
  left: 49.5%;
  z-index: 10;
  // opacity: 0.7;
  .green {
    display: none;
  }
  .default {
    // display: block;
    display: inline;
  }
  &:hover {
    .green {
      // display: block;
      display: inline;
    }
    .default {
      display: none;
    }
  }
}
.map_east {
  position: absolute;
  cursor: pointer;
  top: 52.5%;
  left: 71.5%;
  z-index: 10;
  // opacity: 0.7;
  .green {
    display: none;
  }
  .default {
    // display: block;
    display: inline;
  }
  &:hover {
    .green {
      // display: block;
      display: inline;
    }
    .default {
      display: none;
    }
  }
}
.map_northeast {
  position: absolute;
  cursor: pointer;
  top: 1%;
  left: 74.5%;
  z-index: 10;
  // opacity: 0.7;
  .green {
    display: none;
  }
  .default {
    // display: block;
    display: inline;
  }
  &:hover {
    .green {
      // display: block;
      display: inline;
    }
    .default {
      display: none;
    }
  }
}
.map_north {
  position: absolute;
  cursor: pointer;
  top: 2%;
  left: 40%;
  z-index: 10;
  // opacity: 0.7;
  .green {
    display: none;
  }
  .default {
    // display: block;
    display: inline;
  }
  &:hover {
    .green {
      // display: block;
      display: inline;
    }
    .default {
      display: none;
    }
  }
}
.web_col:not(:last-child) {
  margin-bottom: 10px;
}
// 联系我们-左边-七大区域列表
.map_div {
  cursor: pointer;
  background: #1d87ce;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  line-height: 14px;
  &:hover {
    background: linear-gradient(
      0deg,
      rgba(36, 173, 15, 0.58),
      rgba(29, 135, 206)
    ) !important;
    transform: scale(1.02);
    transition: 0.5s;
  }
}
.recruit_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
.recruit_div {
  padding: 30px;
  opacity: 0.8;
  background: #195594;
  border-radius: 8px;
  &:hover {
    transform: scale(1.03);
    transition: 0.5s;
  }
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}
.swiper_recruit {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.5);
  // background: #24ad0f;
  border-radius: 5px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    background: linear-gradient(
      0deg,
      rgba(36, 173, 15, 0.58),
      rgba(14, 114, 255, 0.5)
    ) !important;
    // transform: scale(1.06);
    // transition: 0.5s;
    border-radius: 5px;
  }
}
.esg_hover {
  cursor: pointer;
  // transform: scaleY(1);
  &:hover {
    transform: scale(1.03);
    // -webkit-transition: all 0.6s ease-in-out;
    // transition: all 0.6s ease-in-out;
    transition: 0.5s;
    background-size: 104%;
    // background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &:focus {
    transition: 0.5s;
  }

  &:hover::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    background: url("@/assets/images/esg4.png");
  }
}
// 数字化平台-选中样式
.slide-card.active {
  position: relative;
  box-sizing: border-box;
  // width: 380px;
  // height: 340px;
  width: 960px;
  height: 525px;
  border-radius: 8px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  // background: #01339c;
  transition: all 0.6s;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    width: 960px;
    .descriptions-wrapper {
      background: transparent;
      -webkit-text-fill-color: unset;
    }
    .logo_title {
      width: 200px;
      text-align: left;
    }
    .desc_p {
      top: 100%;
    }
  }
  .desc_p {
    top: 100%;
  }
  .logo {
    position: absolute;
    top: 36px;
    left: 40px;
    height: 36px;
  }
  .logo_title {
    position: absolute;
    top: 38px;
    left: 95px;
    width: 200px !important;
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
  }

  .descriptions {
    position: absolute;
    top: 110px;
    left: 40px;
    opacity: 1;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #b6ceff;
      line-height: 32px;
      // width: 43%;
      width: 300px;
      max-width: 500px;
      text-indent: 2em;
      text-align: justify;
      height: 285px;
    }
  }
  .descriptions-wrapper {
    // background-clip: text;
    // -webkit-text-fill-color: transparent;
    background: transparent;
    -webkit-text-fill-color: unset;
  }
}

// 未选中样式
.slide-card {
  position: relative;
  box-sizing: border-box;
  // width: 380px;
  // height: 340px;
  width: 240px;
  height: 525px;
  border-radius: 8px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  // background: #01339c;
  transition: all 0.6s;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    width: 960px;
    .descriptions-wrapper {
      background: transparent;
      -webkit-text-fill-color: unset;
    }
  }
  .desc_p {
    position: absolute;
    bottom: 20%;
    left: 13%;
    width: 150px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #b6ceff;
    // line-height: 32px;
    transition: all 0.4s ease 0.16s;
  }

  .logo {
    position: absolute;
    top: 30%;
    left: 40%;
    // transform: translate(-50%, -50%);
    height: 36px;
    transition: all 0.4s ease 0.16s;
  }
  .logo_title {
    position: absolute;
    top: 40%;
    left: 22%;
    width: 108px;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    transition: all 0.4s ease 0.16s;
  }

  .descriptions {
    position: absolute;
    // top: 110px;
    transition: all 0.4s ease 0.16s;
    top: 100%;
    left: 40px;
    opacity: 1;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #b6ceff;
      line-height: 32px;
      // width: 78%;
      width: 464px;
      max-width: 500px;
      text-indent: 2em;
      height: 285px;
    }
  }
  .descriptions-wrapper {
    background: linear-gradient(270deg, hsla(0, 0%, 93.3%, 0), #d8d8d8);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.news_hover {
  cursor: pointer;
  &:hover {
    border-bottom: 4px solid #24ad0f;
    transform: scale(1.02);
    transition: 0.5s;
  }
}
/*新闻中心-推荐新闻轮播*/
.pie_item {
  position: relative;
  height: 100%;
  cursor: pointer;
  .green {
    display: none;
  }
  .blue {
    display: block;
  }
  &:hover {
    .green {
      display: block;
    }
    .blue {
      display: none;
    }
  }
  .radius_white {
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: 2rem;
    top: 45px;
  }
  .pie_item_p1 {
    position: absolute;
    left: 2rem;
    top: 2rem;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
  }
  .pie_item_p2 {
    position: absolute;
    left: 2rem;
    top: 10rem;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    width: 80%;
  }
  .pie_item_p3 {
    position: absolute;
    left: 2rem;
    top: 15rem;
    text-indent: 2em;
    font-size: 14px;
    line-height: 24px;
    color: #b6ceff;
    width: 80%;
  }
}
/*按钮悬浮*/
.el-button:hover {
  background: #24ad0f !important;
  color: #fff !important;
  border-color: #24ad0f !important;
  font-weight: 600 !important;
}

#wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
#main {
  position: relative;
  transition: top 0.8s;
}
.hover-opacity {
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
    font-size: 20px !important;
    background: rgba(36, 173, 15, 0.58);
    border-radius: 5px;
    padding: 10px;
    text-align: center;
  }
}
.hover-opacity2 {
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
    background: rgba(36, 173, 15, 0.58);
    border-radius: 5px;
    text-align: center;
  }
}
.light {
  .green {
    display: none;
  }
  .black {
    display: inline-block;
  }
  &:hover {
    .green {
      display: inline-block;
    }
    .black {
      display: none;
    }
  }
}
.title-p-mobile {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  line-height: 20px;
  margin-bottom: 20px;
}
.title-p {
  position: absolute;
  top: 12px;
  left: 40px;
  font-size: 32px;
  font-weight: 600;
  color: #195594;
}
.title-val {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  margin-bottom: 25px;
  text-indent: 2em;
  text-align: justify;
}
.hexinTitle {
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 4px;
}
.hexinTitle-mobile {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 4px;
}
.titles-mobile {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 14px;
}
.bgfooter {
  position: relative;
  top: var(--topHeight);
  padding: 12px 35px;
  color: #fff;
  background: linear-gradient(270deg, #36a0db, #18469a);
}
.video {
  position: absolute;
  bottom: 40px;
  left: 60px;
}
.item-div {
  cursor: pointer;
  opacity: 0.9;
  background: #195594;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  position: relative;
}
/*核心业务样式*/
.div-hover {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    // opacity: 0.9;
    // font-size: 20px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.item-span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.item-span2 {
  position: absolute;
  // left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}
.div_hover_gtly {
  &:hover {
    transform: scale(1.03);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 24px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.03);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/gtly.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_tsly {
  &:hover {
    transform: scale(1.03);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 24px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.03);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/tsly.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_ssly {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/ssly.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_gsly {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/gsly.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_nmjzx {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/nmjzx.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_wmjzx {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/nmjzx.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_glgx {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/glgx.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_gcwl {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/gcwl.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_ccps {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/ccps.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_cbzp {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/cbzp.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_dzspgyl {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/dzspgyl.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_byfw {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/byfw.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_cbgl {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/cbgl.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_nmszh {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/nmszh.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_jrwl {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/jrwl.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_sgj {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/sgj.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_gkwl {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/gkwl.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_wmszh {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/wmszh.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_cbjy {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/cbjy.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.div_hover_gengduo {
  &:hover {
    transform: scale(1.06);
    transition: 0.5s;
    border-radius: 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover::before {
    content: " ";
    transform: scale(1.06);
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("@/assets/images/gengduo.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>
<style scoped>
@import "@/styles/animate.css";
</style>

<template>
  <div id="app">
    <router-view ref="Childmain" />
  </div>
</template>
<script>
import md5 from "js-md5";
import * as services from "@/api/website";
import DevicePixelRatio from "@/utils/rem";

export default {
  data() {
    return {
      userName: null,
      unread: 100,
    };
  },
  created() {
    // this.$store.dispatch("getDoorInfo");
    this.getInfo();
    this.getConfig();
    // new DevicePixelRatio().init()
  },
  mounted() {
    this.getBrowserInfo();

    // 	let JIM = new JMessage({debug:true});
    // 	this.$store.commit('getJIM',JIM)
    // 	if (!!window.ActiveXObject || "ActiveXObject" in window ) {
    // 		this.$store.commit('getUser','userIe')
    // 	}else if(navigator.userAgent.toLowerCase().indexOf("chrome") == -1) {
    // 		// chrome
    // 		this.$store.commit('getUser','user2')
    // 	}
    // 	else{
    // 		this.$store.commit('getUser','admin')// 管理员
    // 	}
    // 	let that = this
    // 	let {userName_self} = this.$store.state
    // 	if (userName_self != 'admin') {
    // 		that.$store.commit('pushUserList',{
    // 		"msgs": [
    // 				{
    // 						"msg_type": 3,
    // 						"content": {
    // 							"msg_body": {
    // 									"text": "有什么可以帮到您的"
    // 							},
    // 							"msg_type": "text"
    // 						}
    // 				}
    // 		],
    // 		"from_username": "admin",
    // 		"from_appkey": "5992c2490e709d759fba13f1",
    // 		"msg_type": 3,
    // 		"unread_msg_count": ''
    // 	})
    // 	}
    // 	let {state} = this.$store
    // 	let appKey = '5992c2490e709d759fba13f1'
    // 	let random = this.generateRamStr(20)
    // 	let timestamp = new Date().getTime()
    // 	let secret = '134d9f6f225e4c4c3136f3c2'
    // 	let md5Str = md5(`appkey=${appKey}&timestamp=${timestamp}&random_str=${random}&key=${secret}`)
    // 	// JIM.init({
    // 	// 	"appkey" : appKey,
    // 	// 	"random_str" : random,
    // 	// 	"signature" : md5Str,
    // 	// 	"timestamp" : timestamp,
    // 	// 	"flag" : 0
    // 	// }).onSuccess(function(data) {
    // 	// 		that.JIMlogin(JIM, userName_self)
    // 	// 	})
    // 	// 	// 在线监听
    // 	// 	JIM.onMsgReceive(function(data) {
    // 	// 		console.log(1111111,data);
    // 	// 		data.messages.map(item => {
    // 	// 			if (item.content.target_id === userName_self) {
    // 	// 				let num = state.userList.findIndex(item1 => item1.from_username == item.from_username)
    // 	// 				console.log(num,55476);
    // 	// 				if (num != -1) {
    // 	// 					that.$store.commit('pushMsgs',[item,num])
    // 	// 					if (userName_self == 'admin') {
    // 	// 						that.$store.commit('addColorTip',num)
    // 	// 					}
    // 	// 				}else {
    // 	// 					that.$store.commit('pushUserList',{onlineTotal:1,colorTip: true,from_username:item.from_username,msgs:[item]})
    // 	// 				}
    // 	// 				console.log(state.userList,1002356);
    // 	// 				that.$refs.Childmain.openServiceDialog(1,item.from_username)
    // 	// 			}
    // 	// 		})
    // 	// 	})
    // 	// 	//断线监听
    // 	// 	JIM.onDisconnect(function(data) {
    // 	// 	})
    // 	// 	//离线消息同步监听
    // 	// 	JIM.onSyncConversation(function(data) {
    // 	// 		if (userName_self == 'admin') {
    // 	// 			data.map(item => {
    // 	// 				item.colorTip = true
    // 	// 				return item
    // 	// 			})
    // 	// 		}
    // 	// 		that.$store.commit('getUserList',data)
    // 	// 		that.$refs.Childmain.openServiceDialog(1)
    // 	// 	})
  },
  methods: {
    async getConfig() {
      let res = await services.getConfig();
      if (res.code == 200) {
        localStorage.setItem("footerInfo", JSON.stringify(res.rows[0])); //存
      }
    },
    async getInfo() {
      let res = await services.getInfoFilter();
      if (res.code == 200) {
        let arr = res.data[0].voList;

        for (let k of arr) {
          k["type"] = k.menuName;
          k["btnBackground"] = "";
          k["menuList"] = k.voList;
          for (let c of k.menuList) {
            c["name"] = c.menuName;
          }
        }
        localStorage.setItem("tabsList", JSON.stringify(arr)); //存
      }
    },
    JIMlogin(JIM, userName_self) {
      let that = this;
      JIM.login({
        username: userName_self,
        password: "123456",
      })
        .onSuccess(function (data) {
          console.log(data, 3333);
          that.unread = JIM.getUnreadMsgCnt({ username: "user1" });
          var count = JIM.getUnreadMsgCnt({ username: "user2" });
          console.log(123457, count);
          JIM.getConversation()
            .onSuccess(function (data) {
              console.log(33333333, data);
            })
            .onFail(function (data) {});
        })
        .onFail(function (data) {
          console.log(data, 99999999999);
          // 用户不存在时，自动注册，再登录
          if (data.code === 880103) {
            JIM.register({
              username: userName_self,
              password: "123456",
            }).onSuccess(function (data) {
              console.log(data, "自动注册成功");
              that.JIMlogin(JIM, userName_self);
              //data.code 返回码
              //data.message 描述
            });
          }
        });
    },
    generateRamStr(len, charSet) {
      const chars = charSet || "abcdefghijklmnopqrstuvwxyz0123456789";
      let randomStr = "";
      for (var i = 0; i < len; i++) {
        randomStr += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return randomStr;
    },
    // 区分浏览器
    getBrowserInfo() {
      var ua = navigator.userAgent.toLocaleLowerCase();
      var browserType = null;
      if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
        browserType = "IE";
      } else if (ua.match(/firefox/) != null) {
        browserType = "火狐";
      } else if (ua.match(/ubrowser/) != null) {
        browserType = "UC";
      } else if (ua.match(/opera/) != null) {
        browserType = "欧朋";
      } else if (ua.match(/bidubrowser/) != null) {
        browserType = "百度";
      } else if (ua.match(/metasr/) != null) {
        browserType = "搜狗";
      } else if (
        ua.match(/tencenttraveler/) != null ||
        ua.match(/qqbrowse/) != null
      ) {
        browserType = "QQ";
      } else if (ua.match(/maxthon/) != null) {
        browserType = "遨游";
      } else if (ua.match(/chrome/) != null) {
        var is360 = this._mime(
          "type",
          "application/vnd.chromium.remoting-viewer"
        );
        if (is360) {
          browserType = "360";
        } else {
          browserType = "谷歌";
        }
      } else if (ua.match(/safari/) != null) {
        browserType = "Safari";
      }
      this.$store.commit("setBrowserType", browserType);
    },
    _mime(option, value) {
      var mimeTypes = navigator.mimeTypes;
      for (var mt in mimeTypes) {
        if (mimeTypes[mt][option] == value) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="less">
// google隐藏滚动条
*::-webkit-scrollbar {
  display: none;
}
// 火狐隐藏滚动条
* {
  scrollbar-width: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  -webkit-text-size-adjust: 100% !important;
}
</style>

import axios from "axios";
import { Message } from "element-ui";
// import {Base64} from 'js-base64';
// import website from 'common/utils/website';
// import {getToken} from 'common/utils/auth';
import * as env from "@/config/env";
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000, // request timeout
});

// 默认的自定义配置
const customDefault = {
    formData: false, // 是否发送 formData 数据，默认为 false
    errorMsg: true, // 是否显示接口错误信息弹窗，默认为 true
};
// request interceptor
service.interceptors.request.use(
    (config) => {
        const custom = {...customDefault, ...config.custom };
        // 判断是哪个应用id
        config.headers["applicationid"] = env.applicationid;
        // 暂时写死，到时候搞成微信扫一扫登录了就
        config.headers["Authorization"] = "Basic c2FiZXI6c2FiZXJfc2VjcmV0";
        config.headers["is_token"] = "smsapi";
        config.headers["Blade-Auth"] =
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRfaWQiOiI3ODExNTUiLCJkZXB0TmFtZSI6IiIsImF1dGhzdGF0ZSI6MiwidXNlcl9uYW1lIjoiY2hlbmppZSIsInJlYWxfbmFtZSI6IumZiOadsCIsImNsaWVudF9pZCI6InNhYmVyIiwidGVuYW50TmFtZSI6Iua3seWcs-W4guS4rea1t-mAmueJqea1geiCoeS7veaciemZkOWFrOWPuCIsInJvbGVfaWQiOiIxMjY0ODE4MDc5MzYwMDYxNDQxLDEyNjIzMjI3ODg3OTQ4NjM2MTcsMTM3NDY0NDUzODExNDU3Mjg5MiwxMjY2Mjc4MDQ1NTIyNTM4NDk3IiwiaGFzQmluZFdlY2hhdCI6ZmFsc2UsInNjb3BlIjpbImFsbCJdLCJwb3N0TmFtZSI6IuaKgOacr-aAu-ebkSIsIm9hdXRoX2lkIjoiIiwiZXhwIjoxNjY0OTMyMDkyLCJqdGkiOiJiZDQ3YmMxNy00NDgzLTRkOTQtYjEwZi04ZDQ5MTVlZTgwMWUiLCJlbWFpbCI6ImNoZW5qaWVAc2lub2NlYW4uY29tIiwicm9sZUFsaWFzIjoiYWRtaW4sYWRtaW5pc3RyYXRvcixmaW5hbmNlLOiWqumFrOeuoeeQhiIsImF2YXRhciI6Imh0dHA6Ly9mLnNpbm9jZWFuLmNvbS9ncm91cDEvTTAwLzAwLzU5L3dLaDdPRjZ5ZlJ5QUNsVXFBQUZyQjNvU0gwczY1MC5qcGciLCJhdXRob3JpdGllcyI6WyJhZG1pbiIsImFkbWluaXN0cmF0b3IiLCLolqrphaznrqHnkIYiLCJmaW5hbmNlIl0sInJvbGVfbmFtZSI6ImFkbWluLGFkbWluaXN0cmF0b3IsZmluYW5jZSzolqrphaznrqHnkIYiLCJsaWNlbnNlIjoicG93ZXJlZCBieSBibGFkZXgiLCJwb3N0X2lkIjoiMTI4ODAxMzY2MTEzMDAwMDA1MiIsInVzZXJfaWQiOiIxMzI3MTQ4NTc4NTU2MDI2ODgyIiwicGhvbmUiOiIxNTgxODc5MTc5NSIsIm5pY2tfbmFtZSI6IuadsCIsImN1c3RvbWVyaWQiOiIxMTkxMTg4MTA5OTIiLCJwb3N0Q29kZSI6IjA1MiIsImRlcHRfaWQiOiIyMDA2MTAxMTQ3NDc2NjkwMDA1IiwiY2F0ZWdvcnkiOiIyIiwiYWNjb3VudCI6ImNoZW5qaWUifQ.r-nPhsk94-DlrbxTolES3BnqWdCQz8c7ordH-dJYalw";
        if (custom.formData) {
            config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        }
        if (config.option) {
            for (let item in config.option)
                config.headers[item] = config.option[item];
        }
        return config;
    },
    (error) => {
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const custom = {...customDefault, ...response.config.custom };
        const res = response.data;
        if (res.code !== 0 && res.code !== 200 && res.code !== 201) {
            if (custom.errorMsg)
                Message.error({ message: res.msg || res.message || "系统异常" });
            return Promise.reject(res);
        }
        return res;
    },
    (error) => {
        const custom = {...customDefault, ...error.response.config.custom };
        if (custom.errorMsg)
            Message.error({
                message: error.response.data.msg ||
                    error.response.data.message ||
                    "网络错误，请稍后重试",
            });
        console.error(error.response);
        return Promise.reject(error.response.data);
    }
);

export default service;
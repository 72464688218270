import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./utils/http";
import * as env from "@/config/env";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./element-ui";
import "./styles/index.less";
import "./styles/class.less";
import scroll from "vue-seamless-scroll";
import "wowjs/css/libs/animate.css";
import wow from "wowjs";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// import "fullpage.js/vendors/scrolloverflow";
import VueFullpage from "vue-fullpage.js";
import VueAnimateNumber from "vue-animate-number";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import LyTab from "ly-tab";
Vue.use(LyTab);
Vue.use(AOS);
Vue.use(VueAnimateNumber);
Vue.prototype.$wow = wow;
Vue.use(scroll);
// 全屏滚动 vue-fullpage.js
// Vue.use(VueFullpage);
Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$env = env;
Vue.use(ElementUI, { size: "small" });
// Vue.use(Swiper)
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
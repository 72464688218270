import Vue from "vue";
import Vuex from "vuex";
import * as service from "./service";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        doorInfo: {},
        JIM: null,
        userName_self: "",
        userList: [],
        browserType: "",
        tabsList: [],
    },
    getters: {},
    mutations: {
        changeValue(state, data) {
            state.tabsList = data;
        },
        setBrowserType(state, data) {
            state.browserType = data;
        },
        getDoorInfo(state, data) {
            // data.download = JSON.parse(data.download);
            state.tabsList = data;
        },
        getJIM(state, res) {
            state.JIM = res;
        },
        getUser(state, res) {
            state.userName_self = res;
        },
        getChatList(state, textList) {
            state.chatList = state.chatList.concat(textList);
        },
        getUserList(state, userList) {
            state.userList = userList;
        },
        pushMsgs(state, arr) {
            let [item, index] = arr;
            state.userList[index].msgs.push(item);
        },
        addColorTip(state, index) {
            state.userList[index].colorTip = true;
            // 前端计数，有几条未读消息（在线监控情况下）
            if (!state.userList[index].onlineTotal) {
                state.userList[index].onlineTotal = 1;
            } else if (state.userList[index].onlineTotal >= 1) {
                state.userList[index].onlineTotal++;
            }
        },
        pushUserList(state, item) {
            state.userList.push(item);
        },
        changeUnread(state, index) {
            state.userList[index].unread_msg_count = 0;
            state.userList[index].onlineTotal = 0;
            state.userList[index].colorTip = false;
        },
    },
    actions: {
        // getDoorInfo(context) {
        //     service
        //         .httpGet("/blade-workorder/portalinfo/getPortalinfo")
        //         .then((res) => {
        //             context.commit("getDoorInfo", res.data);
        //         });
        // },
        //
        // getDoorInfo(context) {
        //     service.httpGet("/website/menu/listEnable").then((res) => {
        //         context.commit("getDoorInfo", res.data);
        //         console.log("res.data: ", res.data);
        //     });
        // },
    },
    modules: {},
});
<template>
  <div>
    <!-- 分割线 -->
    <el-row class="row-height">
      <el-col class="nav-col" :span="8" style="background: #24ad0f">&nbsp;</el-col>
      <el-col class="nav-col" :span="8" style="background: #a81616">&nbsp;</el-col>
      <el-col class="nav-col" :span="8" style="background: #2f6291">&nbsp;</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "dividingLine",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
// 屏幕小于780
@media screen and (max-width: 780px) {
  .nav-col{
    height: 6px;
  }
  .row-height{
    height: 6px;
  }
}
// 屏幕大于1200
@media screen and (min-width: 1200px) {
 .nav-col{
    height: 100%;
  }
  .row-height{
    height: 100%;
  }
}
</style>

/* 防抖 */
export function debounce(func, delay) {
  let timer = null
  return function () {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(() => {
      func(...arguments)
    }, delay)
  };
}

/* 节流 */
export function throttle(func, delay) {
  let time = null
  return function () {
    let args = Array.from(arguments)
    if (time === null) {
      time = setTimeout(() => {
        func(...args)
        clearTimeout(time)
        time = null
      }, delay)
    }
  }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"videoContainer",style:({ height: (_vm.screenHeight + "px") })},[(_vm.videoPath && _vm.showWeb)?_c('video',{ref:"vipUnpack",staticStyle:{"width":"100%"},attrs:{"id":"'video_mp4_0","autoplay":"","muted":"","loop":"","webkit-playsinline":"","playsinline":"","x5-playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.videoPath,"type":"video/mp4"}})]):_vm._e(),_c('div',{staticClass:"headerColor",staticStyle:{"z-index":"1000","position":"fixed","top":"0","left":"50%","transform":"translateX(-50%)","width":"100%","height":"70px"},style:({
        background: _vm.currentScroll > 650 || !_vm.showWeb ? '#195594' : '',
      }),on:{"mouseleave":_vm.mouseleave}},[_c('el-row',[_c('el-col',{attrs:{"xs":23,"lg":4,"xl":3}},[_c('div',{staticClass:"pointer nav-logo flex justify-between"},[_c('img',{attrs:{"src":_vm.footerInfo.logo},on:{"click":_vm.toIndex}}),(!_vm.showWeb)?_c('div',{staticClass:"padding-sm",on:{"click":_vm.openNavmenu}},[(!_vm.navmenu)?_c('img',{attrs:{"src":_vm.navicon,"alt":""}}):_c('img',{attrs:{"src":_vm.close,"alt":""}})]):_vm._e()])]),(_vm.showWeb)?_c('el-col',{style:({
            background: _vm.currentScroll > 650 ? '#a81616' : '',
          }),attrs:{"lg":1,"xl":1}},[_c('div',{staticClass:"text-center pointer hoverChange",staticStyle:{"height":"70px","padding":"24px 18px"},on:{"click":function($event){return _vm.$router.push('/')},"mouseover":function($event){return _vm.mouseleave()}}},[_c('img',{attrs:{"src":require('@/assets/images/homeIcon.png'),"alt":""}})])]):_vm._e(),(_vm.showWeb)?_c('el-col',{staticClass:"flex justify-between",staticStyle:{"height":"70px"},attrs:{"lg":19,"xl":20}},[_vm._l((_vm.tabsList),function(item,index){return _c('div',{key:index,staticClass:"hoverChange",staticStyle:{"position":"relative","padding":"13px 18px"},style:({
              background: item.btnBackground,
              padding: _vm.titleStyle ? '13px 38px' : '13px 18px',
            }),on:{"mouseover":function($event){return _vm.showMenu(item.type)}}},[_c('el-button',{staticStyle:{"color":"white","font-size":"18px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.jumpHome(item)}}},[_vm._v(_vm._s(item.type))]),(_vm.menuName == item.type && _vm.showNav)?_c('div',{staticClass:"scroll_style",staticStyle:{"position":"absolute","left":"0","top":"70px","background":"#fff","box-shadow":"0px 2px 4px 0px rgba(0, 0, 0, 0.2)","padding":"16px","width":"100%","text-align":"center","max-height":"368px","overflow":"scroll","overflow-x":"hidden"},style:({
                width: _vm.menuName == '创新产品' ? '203px' : '100%',
                textAlign: _vm.menuName == '创新产品' ? 'left' : 'center',
              }),attrs:{"id":"scroll"},on:{"mouseleave":_vm.mouseleave,"mousewheel":_vm.scrollChange}},_vm._l((item.menuList),function(v,i){return _c('p',{key:i,staticClass:"menu_p",style:({
                  lineHeight: _vm.menuName == '创新产品' ? '22px' : '20px',
                }),on:{"click":function($event){return _vm.toDetail(v, item)}}},[(_vm.menuName == item.type)?[_vm._v(" "+_vm._s(v.name)+" "),(i < item.menuList.length - 1)?_c('el-divider'):_vm._e()]:_vm._e(),(
                    i == 6 &&
                    _vm.menuName != '创新产品' &&
                    _vm.showIcon &&
                    item.menuList.length > 7
                  )?_c('img',{attrs:{"src":_vm.bottomIcon,"alt":""}}):_vm._e(),(i == 3 && _vm.menuName == '创新产品' && _vm.showIcon)?_c('img',{staticStyle:{"margin-left":"48%"},attrs:{"src":_vm.bottomIcon,"alt":""}}):_vm._e()],2)}),0):_vm._e()],1)}),_c('div',[_c('el-button',{staticStyle:{"position":"relative","margin-top":"20px","color":"#fff","margin-right":"40px","font-size":"16px","padding":"3px 10px","width":"80px"},style:({
                background:
                  _vm.currentScroll > _vm.screenHeight ? '#195594' : 'transparent',
              }),attrs:{"size":"mini"},on:{"click":_vm.selectLanguage}},[_vm._v(" "+_vm._s(_vm.language ? "中文" : "EN")+" ")])],1)],2):_vm._e()],1),(!_vm.showWeb && _vm.navmenu)?_c('div',{staticClass:"navigation-content",on:{"mouseleave":_vm.mouseleaveNav}},_vm._l((_vm.tabsList),function(v,i){return _c('div',{key:i},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-collapse-item',{attrs:{"title":v.type,"name":v.type}},[_c('el-row',{attrs:{"gutter":20}},[_vm._l((v.menuList),function(item,index){return [_c('el-col',{key:index,attrs:{"span":v.type == '核心业务' ||
                      v.type == '新闻中心' ||
                      v.type == '多式联运研发中心' ||
                      v.type == '数字化平台'
                        ? 12
                        : v.type == '创新产品'
                        ? 24
                        : 6}},[_c('p',{staticClass:"footerNav",attrs:{"title":item.name},on:{"click":function($event){return _vm.toDetail(item, v)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])]})],2)],1)],1)],1)}),0):_vm._e()],1),(_vm.tabsList.length > 0 && _vm.showWeb)?_c('div',{staticClass:"btext pointer",attrs:{"data-aos":"zoom-in-up","data-aos-duration":"1500","data-aos-offset":"300","data-wow-delay":"500"}},[_c('img',{attrs:{"src":_vm.titleLogo,"alt":""}})]):_vm._e(),(_vm.showWeb)?_c('div',{staticClass:"scroll-more",on:{"click":_vm.toNext}},[_vm._m(0),_c('p',[_vm._v("滑动探索更多")])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b',[_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"font-size":"30px"}})])}]

export { render, staticRenderFns }
// 配置编译环境和线上环境之间的切换
let { domain } = document;
let applicationid;
// applicationid表
// aac1759c126e2cc53f5175d0ac615d65	多式联运智慧大脑
// f32897f43c2293be57ecb6cf35e324c4	多多在途
// 3a207491e0832015ed9f96b321cff6c8	多多联运
// df1318a29e548dbedd54263941e161e0	船货多多
// 466a993e9a2c4b8f63f472c28b5b64c6	中部通道
// 730f0c58877617164ca01a7b2128eab4	联多多
if (process.env.NODE_ENV === "development") {
    let { port } = window.location;

    if (port == "8901") {
        applicationid = "df1318a29e548dbedd54263941e161e0";
    } else if (port == "8902") {
        applicationid = "466a993e9a2c4b8f63f472c28b5b64c6";
    }
} else {
    if (domain == "chdd.sinocean.com") {
        applicationid = "df1318a29e548dbedd54263941e161e0";
    } else if (domain == "zbtd.sinocean.com") {
        applicationid = "466a993e9a2c4b8f63f472c28b5b64c6";
    }
}

export { applicationid };
<template>
  <!-- 首页导航栏 -->
  <div>
    <!-- 背景图/视频 -->
    <div class="videoContainer" :style="{ height: `${screenHeight}px` }">
      <video
        id="'video_mp4_0"
        ref="vipUnpack"
        style="width: 100%"
        autoplay
        muted
        loop
        v-if="videoPath && showWeb"
        webkit-playsinline
        playsinline
        x5-playsinline>
        <source :src="videoPath" type="video/mp4" />
      </video>
      <div
        :style="{
          background: currentScroll > 650 || !showWeb ? '#195594' : '',
        }"
        @mouseleave="mouseleave"
        style="
          z-index: 1000;
          position: fixed;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 70px;
        "
        class="headerColor">
        <el-row>
          <el-col :xs="23" :lg="4" :xl="3">
            <div class="pointer nav-logo flex justify-between">
              <img :src="footerInfo.logo" @click="toIndex" />
              <div v-if="!showWeb" class="padding-sm" @click="openNavmenu">
                <img v-if="!navmenu" :src="navicon" alt="" />
                <img v-else :src="close" alt="" />
              </div>
            </div>
          </el-col>
          <!-- a81616 -->
          <el-col
            v-if="showWeb"
            :lg="1"
            :xl="1"
            :style="{
              background: currentScroll > 650 ? '#a81616' : '',
            }">
            <!-- hvr-overline-from-left -->
            <div
              class="text-center pointer hoverChange"
              @click="$router.push('/')"
              @mouseover="mouseleave()"
              style="height: 70px; padding: 24px 18px">
              <!-- <i class="el-icon-s-home text-white text-xxl" /> -->
              <img :src="require('@/assets/images/homeIcon.png')" alt="" />
            </div>
          </el-col>
          <el-col
            v-if="showWeb"
            :lg="19"
            :xl="20"
            class="flex justify-between"
            style="height: 70px">
            <div
              @mouseover="showMenu(item.type)"
              v-for="(item, index) in tabsList"
              :key="index"
              style="position: relative; padding: 13px 18px"
              class="hoverChange"
              :style="{
                background: item.btnBackground,
                padding: titleStyle ? '13px 38px' : '13px 18px',
              }">
              <el-button
                type="text"
                style="color: white; font-size: 18px"
                @click="jumpHome(item)"
                >{{ item.type }}</el-button
              >

              <div
                id="scroll"
                v-if="menuName == item.type && showNav"
                @mouseleave="mouseleave"
                style="
                  position: absolute;
                  left: 0;
                  top: 70px;
                  background: #fff;
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
                  padding: 16px;
                  width: 100%;
                  text-align: center;
                  max-height: 368px;
                  overflow: scroll;
                  overflow-x: hidden;
                "
                :style="{
                  width: menuName == '创新产品' ? '203px' : '100%',
                  textAlign: menuName == '创新产品' ? 'left' : 'center',
                }"
                class="scroll_style"
                @mousewheel="scrollChange">
                <p
                  v-for="(v, i) in item.menuList"
                  :key="i"
                  class="menu_p"
                  @click="toDetail(v, item)"
                  :style="{
                    lineHeight: menuName == '创新产品' ? '22px' : '20px',
                  }">
                  <template v-if="menuName == item.type">
                    {{ v.name }}
                    <el-divider
                      v-if="i < item.menuList.length - 1"></el-divider>
                  </template>
                  <img
                    :src="bottomIcon"
                    alt=""
                    v-if="
                      i == 6 &&
                      menuName != '创新产品' &&
                      showIcon &&
                      item.menuList.length > 7
                    " />
                  <img
                    style="margin-left: 48%"
                    :src="bottomIcon"
                    alt=""
                    v-if="i == 3 && menuName == '创新产品' && showIcon" />
                </p>
              </div>
            </div>

            <div>
              <el-button
                :style="{
                  background:
                    currentScroll > screenHeight ? '#195594' : 'transparent',
                }"
                size="mini"
                style="
                  position: relative;
                  margin-top: 20px;
                  color: #fff;
                  margin-right: 40px;
                  font-size: 16px;
                  padding: 3px 10px;
                  width: 80px;
                "
                @click="selectLanguage">
                <!-- <img :src="language" class="margin-right-xs" /> -->
                {{ language ? "中文" : "EN" }}
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              </el-button>
              <!-- <el-dropdown-menu slot="dropdown" class="dropMenu">
                <el-dropdown-item>英文</el-dropdown-item>
              </el-dropdown-menu> -->
            </div>
          </el-col>
        </el-row>

        <div
          v-if="!showWeb && navmenu"
          class="navigation-content"
          @mouseleave="mouseleaveNav">
          <div v-for="(v, i) in tabsList" :key="i">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item :title="v.type" :name="v.type">
                <el-row :gutter="20">
                  <template v-for="(item, index) in v.menuList">
                    <el-col
                      :span="
                        v.type == '核心业务' ||
                        v.type == '新闻中心' ||
                        v.type == '多式联运研发中心' ||
                        v.type == '数字化平台'
                          ? 12
                          : v.type == '创新产品'
                          ? 24
                          : 6
                      "
                      :key="index">
                      <p
                        class="footerNav"
                        :title="item.name"
                        @click="toDetail(item, v)">
                        {{ item.name }}
                      </p>
                    </el-col>
                  </template>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <!-- 标题 -->
      <div
        v-if="tabsList.length > 0 && showWeb"
        class="btext pointer"
        data-aos="zoom-in-up"
        data-aos-duration="1500"
        data-aos-offset="300"
        data-wow-delay="500">
        <!--  
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="0"
        data-wow-iteration="1"
      -->
        <img :src="titleLogo" alt="" />
      </div>

      <div class="scroll-more" @click="toNext" v-if="showWeb">
        <b>
          <i class="el-icon-arrow-down" style="font-size: 30px"></i>
        </b>
        <p>滑动探索更多</p>
      </div>
    </div>
  </div>
</template>

<script>
import DividingLine from "@/views/components/DividingLine/index";
// import { WOW } from "wowjs";
import "aos/dist/aos.css";
import AOS from "aos/dist/aos.js";
import * as services from "@/api/website";
import titleLogo from "@/assets/images/titleLogo.png";
import bottomIcon from "@/assets/images/bottomIcon.png";
import navicon from "@/assets/images/navicon.png";
import close from "@/assets/images/close.png";
// import language from "@/assets/images/language.png";
// import homeIcon from "@/assets/images/homeIcon.png";
export default {
  name: "headerInfo",
  components: {
    DividingLine,
  },
  props: {
    currentScroll: {
      type: Number,
      default: 0,
    },
    screenHeight: {
      type: Number,
      default: 0,
    },
    tabsList: {
      type: Array,
      default: [],
    },
    footerInfo: {
      type: Object,
      default: {},
    },
    titleStyle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeName: "",
      navmenu: false,
      showWeb: true,
      language: true,
      showNav: false,
      showIcon: true,
      clientWidth: "",
      titleLogo: titleLogo,
      bottomIcon: bottomIcon,
      navicon: navicon,
      close: close,
      // language: language,
      // homeIcon: homeIcon,
      fixStyle: "",
      menuName: "",
      // tabsList: [],
      // footerInfo: {},
      typeObj: {},
      videoPath: "",
    };
  },
  computed: {},
  watch: {
    clientWidth: {
      handler(val) {
        this.showWeb = val > 780 ? true : false;
      },
    },
  },
  created() {
    this.getBanner();
    //--创建页面监听，页面加载完毕--触发音频播放
    // document.addEventListener("DOMContentLoaded", function () {
    //   function audioAutoPlay() {
    //     var musicEle0 = document.getElementById("video_mp4_0");
    //     musicEle0.play();
    //     musicEle0.load();
    //   }
    //   audioAutoPlay();
    // });

    // document.addEventListener(
    //   "WeixinJSBridgeReady",
    //   function () {
    //     var video1 = document.getElementById("video_mp4_0");
    //     video1.play();
    //   },
    //   false
    // );
    // document.addEventListener("WeixinJSBridgeReady", () => {
    // // 执行上述媒体自动播放代码
    // const video = document.getElementById("video");
    // video.play();
    // video.load()
    // });
    // document.body.addEventListener("touchstart", () => {
    // // 执行上述媒体自动播放代码
    // const video = document.getElementById("video");
    // video.play();
    // video.load()
    // }, { once: true });
  },
  mounted() {
    // 获取屏幕宽度
    this.clientWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.clientWidth = document.body.clientWidth;
      })();
    };
    this.$nextTick(() => {
      // new this.$wow.WOW().init();
      AOS.init();
    });
  },
  methods: {
    scrollChange(e) {
      let scroll = document.querySelector("#scroll");
      let that = this;
      scroll.addEventListener("scroll", function (b) {
        let scrollTop = b.target.scrollTop;
        if (scrollTop == 0 && e.wheelDelta >= 120) {
          that.showIcon = true;
        } else {
          that.showIcon = false;
        }
      });
    },
    overAudio() {
      this.$refs.vipUnpack.load();
      this.$refs.vipUnpack.src = this.videoPath;
      this.$refs.vipUnpack.play();
    },
    selectLanguage() {
      this.language = !this.language;
      if (this.language) {
        this.$router.push(`/andHeader/aboutOur?type=关于我们&name=&show=0`);
      } else {
        this.$router.push(`/andHeaderEn/aboutOurEn?type=关于我们&name=&show=0`);
      }
    },
    // 展开侧边栏
    openNavmenu() {
      this.navmenu = !this.navmenu;
      this.$emit("childByValue", this.navmenu);
    },
    // 鼠标离开隐藏侧边栏
    mouseleaveNav() {
      this.navmenu = false;
      console.log("this.navmenu: ", this.navmenu);
    },
    async getBanner() {
      let res = await services.getBanner();
      if (res.code == 200) {
        this.videoPath = res.rows[1].videoPath;
      }
    },
    jumpHome(val, c) {
      let name = c ? c.name : "";
      let type = val.type;
      this.$router.push({
        path: `/andHeader/${val.path}`,
        query: {
          type: type,
          name: name,
          show: (type == "ESG" || type == "multimodal") && name ? 1 : 0,
        },
      });
      localStorage.setItem("routeObj", JSON.stringify(val)); //存
    },

    async toDetail(val, item) {
      let obj = {};
      if (item.type == "核心业务" || item.type == "创新产品") {
        for (let i of this.tabsList) {
          if (i.menuName == item.type) {
            obj = { type: i.menuName, path: i.path, menuType: i.menuType };
          }
        }
        this.typeObj = {
          menuId: val.menuId,
          menuType: val.menuType,
          parentId: val.parentId,
        };
        let res = await services.getSonListEnable({ menuId: val.menuId });
        if (res.code == 200) {
          localStorage.setItem("newsObj", JSON.stringify(res.data[0])); //存
        }
        localStorage.setItem("btnName", val.menuName); //存
        // localStorage.setItem("newsObj", JSON.stringify(val)); //存
        localStorage.setItem("typeObj", JSON.stringify(this.typeObj)); //存

        this.getRecommendList(val.menuId, item, val.menuName);
      } else {
        for (let i of this.tabsList) {
          if (i.menuName == item.type) {
            obj = { type: i.menuName, path: i.path, menuType: i.menuType };
          }
        }
        if (val.menuName == "多式联运创新产品") {
          this.$router.push({
            name: "product",
            query: {
              type: "创新产品",
            },
          });
        } else {
          this.$router.push(
            `/andHeader/${item.path}?type=${item.type}&name=${val.name}&show=1&menuId${val.menuId}`
          );
        }
      }
      localStorage.setItem("routeObj", JSON.stringify(obj)); //存
      this.showNav = false;
    },
    // 获取推荐列表
    async getRecommendList(id, item, btnName) {
      this.typeObj["detailId"] = id;
      let res = await services.getRecommendList(this.typeObj);
      if (res.code == 200) {
        localStorage.setItem("newsList", JSON.stringify(res.data)); //存
        const _time = Date.now();
        // 跳转

        this.$router.replace({
          name: "newsDetail",
          query: {
            time: _time,
            id: id,
            btnName: btnName,
            menuName: item.menuName,
            path: item.path,
            menuType: this.typeObj.menuType,
            parentId: this.typeObj.parentId,
            menuId2: this.typeObj.menuId,
          },
        });
      }
    },
    mouseleave(val) {
      this.showNav = false;
      for (let i of this.tabsList) {
        i.btnBackground = "";
      }
    },
    showMenu(val) {
      this.menuName = val;
      for (let i of this.tabsList) {
        if (val == i.type) {
          i.btnBackground = "#a81616";
        } else {
          i.btnBackground = "";
        }
      }
      this.menuName = val;
      this.showNav = true;
    },
    toIndex() {
      this.$router.push("/");
    },
    toNext() {
      // document.documentElement.scrollTop = 900;
      window.scrollTo({
        top: 900,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
/* Overline From Left */
.hvr-overline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  top: 0;
  background: #a81616;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-overline-from-left:hover:before,
.hvr-overline-from-left:focus:before,
.hvr-overline-from-left:active:before {
  right: 0;
}
/* Underline From Left */
.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #a81616;
  height: 3px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-left:hover:before,
.hvr-underline-from-left:focus:before,
.hvr-underline-from-left:active:before {
  right: 0;
}
</style>
<style lang="less" scoped>
// 屏幕大于1200
@media screen and (min-width: 1200px) {
  .nav-logo {
    width: 100%;
    padding-top: 17px;
    padding-left: 40px;
  }
  .videoContainer {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  .btext {
    position: absolute;
    left: 50%;
    margin-left: -45%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 90%;
    text-align: center;
    z-index: 3;
  }
}
// 屏幕小于780
@media screen and (max-width: 780px) {
  .nav-logo {
    width: 100%;
    padding-top: 17px;
    padding-left: 10px;
  }
  .videoContainer {
    position: relative;
    //  height: 25vh !important;
    overflow: hidden;
    top: 70px;
    //  width: 375px !important;
    height: 70px !important;
  }
  .btext {
    position: absolute;
    left: 50%;
    margin-left: -45%;
    top: 30%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 90%;
    text-align: center;
    z-index: 3;
  }
}
.navigation-content {
  background: #0e243c;
  width: 100%;
  position: absolute;
  top: 70px;
  color: #fff;
  // height: 100vh;
  height: 775px;
  // padding: 10px;
  overflow: scroll;
}
.footerNav {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #a8aeb3;
  line-height: 45px;
}
/deep/.el-collapse-item__header.is-active {
  border-bottom-color: transparent;
  background: linear-gradient(270deg, #0e243c, #d33131) !important;
}
/deep/.el-divider--horizontal {
  margin: 10px 0;
}
/deep/.el-collapse-item__header {
  background-color: #0c1d30;
  border-bottom: 0px;
  color: #fff;
  font-size: 16px;
  padding: 38px 15px;
}
/deep/.el-collapse {
  border-top: 0px;
  border-bottom: 0px;
}
/deep/.el-collapse-item__wrap {
  background-color: #0e243c;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 0px;
  padding: 10px 15px;
}
/deep/.el-collapse-item__content {
  color: #a8aeb3;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}
/deep/.el-divider--horizontal {
  margin: 10px 0;
}
.menu_p {
  &:not(:first-child) {
    margin-top: 15px;
  }
  &:hover {
    color: #24ad0f;
  }
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  line-height: 14px;
}
@-webkit-keyframes moveDownSpot {
  from {
    opacity: 1;
    -webkit-transform: scale(0.6) translateY(0px);
    transform: scale(0.6) translateY(0px);
  }
  to {
    opacity: 0.2;
    -webkit-transform: scale(0.6) translateY(6px);
    transform: scale(0.6) translateY(6px);
  }
}
@-moz-keyframes moveDownSpot {
  from {
    opacity: 1;
    -moz-transform: scale(0.6) translateY(0px);
    transform: scale(0.6) translateY(0px);
  }
  to {
    opacity: 0.2;
    -moz-transform: scale(0.6) translateY(6px);
    transform: scale(0.6) translateY(6px);
  }
}
@-o-keyframes moveDownSpot {
  from {
    opacity: 1;
    -o-transform: scale(0.6) translateY(0px);
    transform: scale(0.6) translateY(0px);
  }
  to {
    opacity: 0.2;
    -o-transform: scale(0.6) translateY(6px);
    transform: scale(0.6) translateY(6px);
  }
}
@keyframes moveDownSpot {
  from {
    opacity: 1;
    -webkit-transform: scale(0.6) translateY(0px);
    -moz-transform: scale(0.6) translateY(0px);
    -o-transform: scale(0.6) translateY(0px);
    transform: scale(0.6) translateY(0px);
  }
  to {
    opacity: 0.2;
    -webkit-transform: scale(0.6) translateY(6px);
    -moz-transform: scale(0.6) translateY(6px);
    -o-transform: scale(0.6) translateY(6px);
    transform: scale(0.6) translateY(6px);
  }
}

.nav_menu_text {
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #333;
  line-height: 16px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &:hover {
    color: #24ad0f;
    font-weight: 600;
  }
}
.scroll-more {
  position: absolute;
  cursor: pointer;
  left: 50%;
  margin-left: -50px;
  bottom: 44px;
  z-index: 10;
  text-align: center;
}
.scroll-more b {
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.scroll-more p {
  margin-top: 18px;
  line-height: 1;
  color: #fff;
  font-size: 16px;
}
.scroll-more b i {
  display: block;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  -webkit-animation: moveDownSpot 1000ms infinite;
  -moz-animation: moveDownSpot 1000ms infinite;
  -o-animation: moveDownSpot 1000ms infinite;
  animation: moveDownSpot 1000ms infinite;
}

.hoverChange {
  position: relative;
  &:hover {
    // color: #a81616 !important;
    background: #a81616;
    // border-radius: 8px;
    // box-shadow: 0 10px 40px 0 rgba(22, 22, 22, 0.1);
    // margin: -5px 0px 0 0 ;
    // top: -3px;
    // transform: scale(1.3);
  }
  .scroll_style::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
    // height:16px;
    display: block !important;
  }
  .scroll_style ::-webkit-scrollbar-track {
    // background-color: #F66666;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    display: none !important;
  }
  .scroll_style ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    height: 16px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    background-color: #999;
    display: block !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    height: 16px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    background-color: #999;
    display: block !important;
  }
}
.dropMenu li {
  padding: 5px 25px !important;
  font-size: 14px !important;
  color: rgb(51, 51, 51) !important;
}
.fillWidth {
  width: 100%;
}
.headerColor {
  &:hover {
    background: #195594 !important ;
  }
}
</style>

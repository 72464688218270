/* eslint-disable func-names */
import { Dialog, Input, Select, TableColumn, DatePicker, Cascader } from 'element-ui' // Upload

// input 功能扩展
Input.props.width = {
  type: String
}
Input.beforeMount = function() {
  if (this.width) {
    // 设置宽度
    this.$nextTick(() => {
      this.$el.style.width += this.width
    })
  }
}

// select 功能扩展
Select.props.width = {
  type: String
}
Select.beforeMount = function() {
  if (this.width) {
    this.$nextTick(() => {
      this.$el.style.width += this.width
    })
  }
}

// Cascader 功能扩展
Cascader.props.width = {
  type: String
}
Cascader.beforeMount = function() {
  if (this.width) {
    this.$nextTick(() => {
      this.$el.style.width += this.width
    })
  }
}

// 日期控件 功能扩展
DatePicker.props.width = {
  type: String
}
DatePicker.beforeMount = function() {
  if (this.width) {
    this.$nextTick(() => {
      this.$el.style.width += this.width
    })
  }
}

// 表格功能扩展
TableColumn.props.renderHeader = {
  type: Function,
  // eslint-disable-next-line no-unused-vars
  default: function(h, { column, $index }) {
    // base-colum-required
    if (!column.label) {
      return false
    }
    if (column.minWidth !== 80) {
      return h('span', [column.label])
    }
    const l = column.label.length
    const f = 12
    column.minWidth = f * l + 42
    return h('div', [column.label])
  }
}

// Dialog默认点击空白处不关闭
Dialog.props.closeOnClickModal = false
// 上传文件跨域请求默认携带cookie
// Upload.props.withCredentials = {
//   type: Boolean,
//   default: true
// }

<template>
  <div>
    <div class="footer" v-if="footerInfo">
      <div
        v-if="showWeb"
        style="margin-top: 50px; border-bottom: 1px solid #283749"
        class="flex justify-between"
      >
        <div>
          <div style="margin-bottom: 50px" v-if="footerInfo.bottomLogo">
            <img :src="footerInfo.bottomLogo" alt="" />
          </div>
          <div style="width: 100%" class="text-center">
            <img v-if="footerInfo.weixin" :src="footerInfo.weixin" alt="" />
            <div class="text-white margin-top-sm">中海通公众号</div>
          </div>
        </div>

        <div class="flex" style="margin-bottom: 35px">
          <div
            class="text-white left_not"
            v-for="(v, i) in tabsList"
            :key="i"
            :style="{ '--width': marginLeft }"
          >
            <div class="text-df margin-bottom-xl pointer" @click="toDetail(v)">
              {{ v.type }}
            </div>
            <template v-if="v.menuList && v.menuList.length > 0">
              <div
                v-for="(item, index) in v.menuList.slice(0, 9)"
                :key="index"
                style="color: #999; width: 100px"
                class="margin-bottom pointer footerTitle"
                @click="toDetail(v, item)"
                :title="item.name"
              >
                <p
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ item.name }}
                </p>
              </div>
            </template>
          </div>
        </div>

        <div>
          <div class="text-white margin-bottom-xl">
            <div class="margin-bottom-xs text-df">总部电话</div>
            <div style="color: #999" v-if="footerInfo.companyPhone">
              {{ footerInfo.companyPhone }}
            </div>
          </div>

          <div class="text-white margin-bottom-xl">
            <div class="margin-bottom-xs text-df">联系我们</div>
            <div style="color: #999" v-if="footerInfo.companyEmail">
              {{ footerInfo.companyEmail }}
            </div>
          </div>

          <div class="text-white margin-bottom-xl">
            <div class="margin-bottom-xs text-df">总部地址</div>
            <div style="color: #999" v-if="footerInfo.companyAddress">
              {{ footerInfo.companyAddress }}
            </div>
          </div>
        </div>
      </div>
      <template v-if="!showWeb">
        <div  
          v-for="(v, i) in tabsList"
          :key="i" 
          >
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item :title="v.type" :name="v.type">
              <el-row :gutter="10">
                <template v-for="(item, index) in v.menuList">
                  <el-col 
                    :span="v.type=='核心业务' || v.type=='新闻中心'|| v.type=='多式联运研发中心' || v.type=='数字化平台' ? 12
                          :v.type=='创新产品'? 24
                          :6" 
                    :key="index">
                    <p class="footerNav" :title="item.name"  @click="toDetail(v, item)">
                      {{item.name}}
                    </p>
                  </el-col>
                </template>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </div>
      </template>
      <div v-if="!showWeb">
        <p @click="selectLanguage" class="lang-mobile">
          <img width="16" src="../../aboutView/img/lang.png" alt="">
          {{language ? "简体中文" : "EN"}}</p>
      </div>

      <div class="btmTextBox">
        <span class="mg_right15" v-if="footerInfo.copyright">{{
          footerInfo.copyright
        }}</span>
        <span class="mg_right15">|</span>
        <span
          class="mg_right15 pointer footerTitle"
          v-if="footerInfo.icp"
          @click="toUrl(footerInfo.icpUrl)"
          >{{ footerInfo.icp }}</span
        >
        <span class="mg_right15">|</span>
        <span
          class="pointer footerTitle"
          v-if="footerInfo.publicSecurity"
          @click="toUrl(footerInfo.publicSecurityUrl)"
          >{{ footerInfo.publicSecurity }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as services from "@/api/website";
export default {
  name: "footerInfo",
  components: {},
  props: {
    tabsList: {
      type: Array,
      default: [],
    },
    footerInfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      language:true,
      activeName:"",
      showWeb:true,
      clientWidth: null,
      marginLeft: "40px",
      // footerInfo: {},
      // tabsList: [],
    };
  },
  computed: {},
  watch: {
    clientWidth: {
      handler(val) {
        this.marginLeft = val > 1440 ? "40px" : "15px";
        this.showWeb = val >780 ? true : false;
      },
    },
  },

  created() {},
  mounted() {
    //获取屏幕尺寸
    this.clientWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.clientWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    toUrl(url) {
      window.open(url);
    },
    selectLanguage(){
      this.language=!this.language
      if(this.language){
        this.$router.push(`/andHeader/aboutOur?type=关于我们&name=&show=0`)
      }else{
        this.$router.push(`/andHeaderEn/aboutOurEn?type=关于我们&name=&show=0`)
      }
    },
    async getConfig() {
      let res = await services.getConfig();
      if (res.code == 200) {
        this.footerInfo = res.rows[0];
        localStorage.setItem("footerInfo", JSON.stringify(this.footerInfo)); //存
      }
    },
    async getInfo() {
      let res = await services.getInfoFilter();
      let arr = res.data[0].voList;
      this.tabsList = arr;
      for (let k of this.tabsList) {
        k["type"] = k.menuName;
        k["btnBackground"] = "";
        k["menuList"] = k.voList;
        for (let c of k.menuList) {
          c["name"] = c.menuName;
        }
      }
    },
    async toDetail(val, item) {
      let obj = {};
      if (val.menuName == "核心业务" || val.menuName == "创新产品") {
        for (let i of this.tabsList) {
          if (i.menuName == val.menuName) {
            obj = { type: i.menuName, path: i.path, menuType: i.menuType };
          }
        }
        this.typeObj = {
          menuId: item.menuId,
          menuType: item.menuType,
          parentId: item.parentId,
        };
        let res = await services.getSonListEnable({ menuId: item.menuId });
        if (res.code == 200) {
          localStorage.setItem("newsObj", JSON.stringify(res.data[0])); //存
        }
        localStorage.setItem("btnName", item.menuName); //存
        // localStorage.setItem("newsObj", JSON.stringify(item)); //存
        localStorage.setItem("typeObj", JSON.stringify(this.typeObj)); //存

        this.getRecommendList(item.menuId, val, item.menuName);
      } else {
        let name = item ? item.name : "";
        let menuId = item ? item.menuId : "";

        for (let i of this.tabsList) {
          if (i.menuName == val.menuName) {
            obj = { type: i.menuName, path: i.path, menuType: i.menuType };
          }
        }
        if (val.menuName == "多式联运创新产品"){
          this.$router.push({
            name: "product",
            query: {
              type: "创新产品",
            },
          });
        } else {
          this.$router.push(`/andHeader/${val.path}?type=${val.menuName}&name=${name}&show=1&menuId=${menuId}`);
        }
      }
      localStorage.setItem("routeObj", JSON.stringify(obj)); //存

      // return;

      // this.$router.push(`/andHeader/${val.path}?type=${val.type}&name=${name}`);
    },
    // 获取推荐列表
    async getRecommendList(id, item, btnName) {
      this.typeObj["detailId"] = id;
      let res = await services.getRecommendList(this.typeObj);
      if (res.code == 200) {
        localStorage.setItem("newsList", JSON.stringify(res.data)); //存
        const _time = Date.now();
        // 跳转

        this.$router.replace({
          name: "newsDetail",
          query: {
            time: _time,
            id: id,
            btnName: btnName,
            menuName: item.menuName,
            path: item.path,
            menuType: this.typeObj.menuType,
            parentId: this.typeObj.parentId,
            menuId2: this.typeObj.menuId,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.left_not:not(:first-child) {
  margin-left: var(--width);
}
.lang-mobile{
  color: #fff;
  margin-left: 15px;
  font-size: 16px;
  height: 80px;
  line-height: 80px;
}
// 屏幕大于1200
@media screen and (min-width: 1200px) {
 .footer {
  width: 100%;
  height: 500px;
  background: #0c1d30;
  // padding: 20px 80px 0 80px;
  padding-top: 20px;
  padding: 10px 15px 0 15px;
  margin: 0;
  //   text-align: center;
 }
 .btmTextBox {
  text-align: center;
  padding: 20px 108px;
  font-size: 12px;
  color: #999;
  .mg_right15 {
    margin-right: 15px;
  }
 }
}
// 屏幕小于780
@media screen and (max-width: 780px) {
  .footer {
  width: 100%;
  // height: 500px;
  background: #0c1d30;
  // padding: 20px 80px 0 80px;
  padding-top: 20px;
  // padding: 10px 15px 0 15px;
  margin: 0;
  //   text-align: center;
 }
 .btmTextBox {
  text-align: center;
  padding: 15px 0px;
  font-size: 12px;
  color: #999;
  .mg_right15 {
    margin-right: 8px;
  }
 }
}

.footerTitle {
  &:hover {
    color: #fff !important;
    // transform: scale(1.1);
  }
}
.footerNav{
   overflow: hidden;
   text-overflow:ellipsis;
	 white-space: nowrap;
   color: #a8aeb3;
   line-height:40px;
}
/deep/.el-collapse-item__header.is-active {
  border-bottom-color: transparent;
  background: linear-gradient(270deg,#0e243c, #d33131) !important;
}
/deep/.el-collapse-item__header{
  background-color:  #0c1d30;
  border-bottom: 0px;
  color: #fff;
  font-size: 16px;
  padding: 30px 10px;
}
/deep/.el-collapse {
  border-top: 0px ;
  border-bottom: 0px ;
}
/deep/.el-collapse-item__wrap {
    background-color:  #0E243C;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: 0px;
    padding: 10px 15px;
}
/deep/.el-collapse-item__content {
    color: #A8AEB3;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
}
</style>

import httpAgent from "@/utils/http";

// 首页信息
export function getInfo() {
    return httpAgent({
        method: "get",
        url: "/website/menu/listEnable",
    });
}

export function getInfoFilter() {
    return httpAgent({
        method: "get",
        url: "/website/menu/listEnableFilter",
    });
}

// 获取微信配置
export function getWxConfig(data) {
    return httpAgent({
        method: "get",
        url: "/common/getWxConfig",
        params: data,
    });
}

// 获取数字化平台推荐列表
export function getdigital(data) {
    return httpAgent({
        method: "get",
        url: "/website/digital/listEnable",
        params: data,
    });
}

export function getSonListEnable(data) {
    return httpAgent({
        method: "get",
        url: "website/menu/getSonListEnable",
        params: data,
    });
}

// 网站基础信息
export function getConfig(data) {
    return httpAgent({
        method: "get",
        url: `/website/config/list`,
    });
}

// 新闻中心首页
export function getArticle(data) {
    return httpAgent({
        method: "get",
        url: `/website/article/listEnable`,
        params: data,
    });
}

// 推荐新闻
export function getRecommendList(data) {
    return httpAgent({
        method: "get",
        url: `/website/menu/getRecommendList`,
        params: data,
    });
}

// 新闻详情分页
export function getSortList(data) {
    return httpAgent({
        method: "get",
        url: `/website/menu/getSortList`,
        params: data,
    });
}

// 荣誉资质图片
export function getPhoto(data) {
    return httpAgent({
        method: "get",
        url: `/website/photo/listEnable`,
        params: data,
    });
}

// 查询招聘列表
export function getJob(data) {
    return httpAgent({
        method: "get",
        url: `/website/job/listEnable`,
        params: data,
    });
}

// 招聘-工作地点
export function getWorkplaceList(data) {
    return httpAgent({
        method: "get",
        url: `/website/job/getWorkplaceList`,
        params: data,
    });
}

// 招聘-推荐列表
export function getJobRecommendList(data) {
    return httpAgent({
        method: "get",
        url: `/website/job/getRecommendList`,
        params: data,
    });
}

// 联系我们-网点信息
export function getSubsidiary(data) {
    return httpAgent({
        method: "get",
        url: `/website/subsidiary/listEnable`,
        params: data,
    });
}

// 多式联运枢纽列表
export function getPivot(data) {
    return httpAgent({
        method: "get",
        url: `/website/pivot/listEnable`,
    });
}

// 数字化产品列表
export function getDigital(data) {
    return httpAgent({
        method: "get",
        url: `/website/digital/listEnable`,
        params: data,
    });
}
// 首页视频
export function getBanner(data) {
    return httpAgent({
        method: "get",
        url: `/website/banner/listEnable`,
    });
}

// 更新新闻点击量
export function articleUpdateClickNum(data) {
    return httpAgent({
        method: "put",
        url: `/website/article/updateShareNum`,
        data: data,
    });
}

// 更新视频点击量
export function videoUpdateClickNum(data) {
    return httpAgent({
        method: "put",
        url: `/website/video/updateShareNum`,
        data: data,
    });
}
// 更新菜单点击量
export function menuUpdateClickNum(data) {
    return httpAgent({
        method: "put",
        url: `/website/menu/updateShareNum`,
        data: data,
    });
}
// 更新数字化平台点击量
export function digitalUpdateClickNum(data) {
    return httpAgent({
        method: "put",
        url: `/website/digital/updateShareNum`,
        data: data,
    });
}
// 更新招聘点击量
export function jobUpdateClickNum(data) {
    return httpAgent({
        method: "put",
        url: `/website/job/updateShareNum`,
        data: data,
    });
}
// 更新子公司点击量
export function subsidiaryUpdateClickNum(data) {
    return httpAgent({
        method: "put",
        url: `/website/subsidiary/updateShareNum`,
        data: data,
    });
}
// 首页菜单详情
export function getMenuDetail(data) {
    return httpAgent({
        method: "get",
        url: `/website/menu/${data}`,
    });
}

// 新闻详情内容
export function articleDetail(data) {
    return httpAgent({
        method: "get",
        url: `/website/article/${data}`,
    });
}

// 视频详情内容
export function videoDetail(data) {
    return httpAgent({
        method: "get",
        url: `/website/video/${data}`,
    });
}

// 数字化产品详情内容
export function digitalDetail(data) {
    return httpAgent({
        method: "get",
        url: `/website/digital/${data}`,
    });
}

// 招聘详情内容
export function jobDetail(data) {
    return httpAgent({
        method: "get",
        url: `/website/job/${data}`,
    });
}